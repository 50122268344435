import React, { Component } from "react";
import Auth from "../services/auth";
import firebase, {return_notification,analytics} from "../services/firebase";
import Loader from "react-loader";
// import "../css/projectlist.css";
const axios = require("axios").default;
const webvtt = require("node-webvtt");
class ClipList extends Component {
  state = {
    projectData: {},
    loaded: false,
    clip_id: "",
    new_proj_name: "",
    srtParsed: false,
    srtArr: [],
    srtData: "",
    loadError: false,
    srtArr: [],
    currTimeStart: "",
    currTimeEnd: "",
    currText: "",    
  };
  componentDidMount() {
    this.getFireBaseData();
    // console.log(this.props.match.params);
    this.handleclick();
  }
  getFireBaseData = () => {
    firebase
      .database()
      .ref()
      .child(
        "users/" +
          Auth.cookies.get("uid") +
          "/projects/" +
          this.props.match.params["project_id"]
      )
      .on("value", async (snapshot) => {
        // console.log("clipList snapshot:", snapshot.toJSON());
        await this.setState({
          projectData: snapshot.toJSON(),
          loaded: true,
          clip_id: this.props.match.params["project_id"],
          new_clip_name: "",
        });
      });
  };

  convertHMS = (value) => {
    const sec = parseInt(value, 10);
    let hours   = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - (hours * 3600)) / 60);
    let seconds = sec - (hours * 3600) - (minutes * 60);
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
  }

  handleclick = () => {
    // 2f6Cn2J9uAhrlF3CugjSaVGmEdM2/-Mu24kI1uwECw5r0zJa3/subtitle.vtt
    var that = this;
    var project_id = this.props.match.params["project_id"];
    var uid = Auth.getUID();
    axios
      .get(
        "https://us-central1-pathrise-convert-1606954137718.cloudfunctions.net/getSignedUrl",
        {
          headers: {
            filename:
              uid + "/" + project_id + "/" + "subtitle.vtt",
          },
        }
      )
      .then((resp) => {
        axios
          .get(resp.data.url)
          .then(async (val) => {
            await this.setState({
              subtitleUrl: resp.data.url,
              srtData: val.data,
              loaded: true,
            });
            var bodyFormData = new FormData();
            var blob = new Blob([val.data], { type: "text/plain" });
            bodyFormData.append("subtitle_file", blob);
            this.handleSubtitleUpload({});
          })
          .catch(function (err) {
            that.setState({
              loadError: true,
            });
            console.log("error inside api call");
          });
      })
      .catch((err) => {
        console.log("Error in getting subtitle file");
        console.log(err);
      });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

    handleSubtitleUpload = (event) => {
    // let reader = new FileReader();
    // reader.readAsText(this.state.srtData);
    //console.log(parseSRT(this.state.srtData));
    //var srt = parseSRT(this.state.srtData);
    const srt = webvtt.parse(this.state.srtData)["cues"];
    var srtArray = [];
    for (var s in srt) {
      //console.log(srt[s]['start']);
      srt[s]["identifier"] = parseInt(srt[s]["identifier"]) - 1;
      srt[s]["id"] = parseInt(srt[s]["identifier"]);
      srt[s]["start"] = this.convertHMS(Number(srt[s]["start"]));
      srt[s]["end"] = this.convertHMS(Number(srt[s]["end"]));
      srtArray.push(srt[s]);
    }

    //console.log(srtArray);
    this.setState({
      loaded: true,
      srtParsed: true,
      srtArr: srtArray,
    });
    // reader.onload = (e) => {
    //   console.log(parseSRT(e.target.result));

    //   // setSubtitleObj(jSONtoStringConverter(parseSRT(e.target.result)));
    // };
  };
  handleSubmit = (e) => {
    e.preventDefault();
    firebase
      .database()
      .ref()
      .child(
        "users/" +
          Auth.cookies.get("uid") +
          "/projects/" +
          this.props.match.params["project_id"] +
          "/clips/"
      )
      .push()
      .set({ name: this.state.new_clip_name, meta_data: "hererer" });
    this.getFireBaseData();
  };
  render() {
    const items = [];
    const itemslist = [];
    let comp;
    const subtitle_items = [];
    if (this.state.loaded) {
      //console.log(Object.keys(this.state.userDataObject.projects));
      if (
        this.state.projectData["clips"] &&
        this.state.projectData["clips"] != "none"
      ) {
        Object.values(this.state.projectData["clips"]).forEach(
          (value, index) => {
            var curr_clip_id = index + 1;
            items.push(
              <div
                onClick={() => {
                  this.props.history.push({
                    pathname: "/srtEdit",
                    state: {
                      clip_id: index,
                      project_id: this.props.match.params["project_id"],
                    },
                  });                  
                }}
                className="clip-item-inner"
                style={{ textDecoration: "none" }}
              >
                <div
                  className={'clip-item'}
                  key={index}
                  style={{ borderRadius: "10px" }}
                >
                  {" Clip " + curr_clip_id}
                  {/* {value.name} */}
                </div>
              </div>
            );
          }
        );
        var srtArray = this.state.srtArr;
        for (var ele in srtArray) {
          itemslist.push(
            <div>
              <div id={ele}>
                <div
                  className="row mobile-clipsection"
                  style={{
                    fontFamily: "Nunito-Regular",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {/* <div className="col-2 font-sizer">
                    {srtArray[ele]["identifier"]}
                  </div> */}
                  <div className="col-1 font-sizer">{srtArray[ele]["start"]}</div>
                  <span> - </span>
                  <div className="col-1 font-sizer">{srtArray[ele]["end"]}</div>
                  <div className="col-4 font-sizer" style={{ textAlign: "left"}}>{srtArray[ele]["text"]}</div>
                </div>
              </div>
            </div>
          );
        }
        comp = <div className="transcript-main">
            <div className="transcript-section">
              <h3>transcript</h3>
              <p>Join our <a href="javascript:void(0)" className="discord">Discord</a> data let us know if you'd like to be able to download the full-episode transcript</p>
            </div>
            <div
              className="row font-sizer"
              style={{
                fontWeight:"bold",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              <div className="col-1 p-2 font-sizer">Start Time</div>
              <div className="col-1 p-2 font-sizer">End Time</div>
              <div className="col-4 p-2 font-sizer" style={{ textAlign: "left"}}>Text</div>
            </div>
            <div
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                textAlign: "left",
              }}
              className="mb-2 item-list-height"
            >
              {itemslist}
            </div>
          </div>
      } else {
        items.push(
          <p
            className="m-3 p-3"
            style={{
              fontSize: "24px",
              fontFamily: "Nunito-bold",
            }}
          >
            No Clips Currently
          </p>
        );
      }
    }
    return (
      <Loader loaded={this.state.loaded} options={{ color: "#87FF65" }}>
        {/* <h1>{this.state.userDataObject.name}</h1> */}
        <div className="container-fluid">
          <br />
          <h3 className="username p-3">{this.state.projectData.name}</h3>
          {/* <div>
            <form onSubmit={this.handleSubmit} className="form-group">
              <div className="row">
                <input
                  name="new_clip_name"
                  placeholder="Enter New Clip Name"
                  required
                  style={{ width: "40%" }}
                  className="form-control m-3 col-lg-3"
                  onChange={this.handleChange}
                  value={this.state.new_clip_name}
                ></input>
                <button className="btn btn-success m-3 col-lg-3">Submit</button>
              </div>
            </form>
          </div> */}
          <div className="row">
            <div className="clip-item-main">{items}</div>
          </div>
          {comp}
          {/* <div className="row">{subtitle_items}</div>
          Uncomment this to get parsed transcript 
          */}
        </div>
      </Loader>
    );
  }
}
export default ClipList;
