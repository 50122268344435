import React, { Component } from "react";
import model_info1 from "../assets/images/model_info1.svg";
import model_info2 from "../assets/images/model_info2.svg";
import model_info3 from "../assets/images/model_info3.svg";
import side_arrow from "../assets/images/side_arrow.svg";
import poster1 from "../assets/images/v1_poster.png";
import poster2 from "../assets/images/v2_poster.png";
import poster3 from "../assets/images/v3_poster.png";
import footerlogo from "../assets/images/footer-logo.svg";
import facebook from "../assets/images/facebook.svg";
import twitter from "../assets/images/twitter.svg";
import Signup from "./signup";
import auth from "../services/auth";

class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.signup = React.createRef();
  }
  handleCTA = () => {
    this.signup.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  render() {
    return (
      <div className="container-fluid" ref={this.signup}>
        <div className="how-it-works-section">
            <h3>Turn All Your Podcast Stories into Highlights In Less Than 30 Minutes!</h3>
            <p>Tassle helps you save roughly 45 minutes of your editing time, make use now!</p>
            <p>Tassle is a podcast editing software with one goal: to cut down long editing times. Do you not get tired of waiting for over an hour to edit a podcast recording? Well, not anymore! Please make use of our unique service and get the job done sooner than you think—no complexities, no confusion, just plain and simple, quick editing.</p>
            <h3>Podcast Editing</h3>
            <p>You have landed on the right page if you have been looking for the best podcast editing software. Turn your audio/video podcast into highlights within no time with our easy-to-use service; sign up on our page, upload the content, and watch it convert in a matter of minutes. It doesn’t get better than this!</p>
            <h3>Tassle Features</h3>
            <p>Tassle offers an array of benefits to the user, whether they are seeking podcast animation or a podcast transcript generator; we are a one-stop solution for all your editing needs. Get done in no time and make the entire process hassle-free.</p>
            <div className="features-include">
              The features include:
              <ul>
                  <li>Podcast Trailer - interesting sneak peeks about what your podcast is all about.</li>
                  <li>Highlight Video Creator – highlights all critical points mentioned in the podcast.</li>
                  <li>Audio into Video – easily convert your video/audio in transcript format</li>
                  <li>Generating Video Transcripts – accurate and exact transcript generation</li>
                  <li>Animation – animate and play around with your podcast with ease</li>
                  <li>Podcast Promos – make attractive promotions of your podcast</li>
              </ul>
            </div>
            <h3>What is our goal?</h3>
            <p>Tassle wants podcast editing to become a fun activity rather than an exhausting task. To make the process seamless and easy to navigate through, we have simplified it entirely.</p>
        </div>
        <div style={{ width: "80%", margin: "auto" }} className="row pricing-table">
          <div className="col-12 flex-center-row podcasting-done-today" style={{marginTop:'30px'}}>
              <a className="try_now new_link" onClick={this.handleCTA}>
                Get your podcasting done today!
              </a>
          </div>
          {/* <div className="col-lg-12 flex-center-row">
            <h3 className="pricing-button">
              <span style={{ textDecoration: "line-through" }}>$7.99</span>{" "}
              <span style={{ fontWeight: "700", fontFamily: "Nunito-regular" }}>
                Free
              </span>
            </h3>
          </div>
          <div style={{ padding: "10px" }}></div>
          <div className="col-lg-12 flex-center-row">
            <h3 className="pricing-info">
              Our focus is on{" "}
              <span style={{ fontFamily: "Nunito-bold" }}>
                creating value + collecting feedback.
              </span>{" "}
              Everyone who signs up today, gets a{" "}
              <span style={{ borderBottom: "5px solid #00CA81" }}>
                3 month free trial.
              </span>
            </h3>
          </div>
          <div style={{ padding: "40px" }}></div> */}
          {/* <div className="flex-center-row" style={{marginTop:'50px'}}>
            <a
              className="try_now new_link"
              onClick={this.handleCTA}
            >
              Sign up and try Tassle now
            </a>
          </div>
          <div style={{ padding: "80px" }}></div>
          <h1 className="gray-color" style={{ width: "100%" }}>
            Have questions / thoughts/ feedback?{" "}
            <a
              style={{ textDecoration: "underline" }}
              className="gray-color"
              href="mailto:tasslexyz@gmail.com?subject=Interested%20in%20using%20Tassle!%20"
            >
              Contact Us
            </a>
          </h1>
          <div style={{ padding: "20px" }}></div> */}
        </div>
        <div className="talk-us-now row">
          <h2>Your podcast can grow with Tassle. 
            <br/><span>Talk to us now.</span>
          </h2>
          <p>Use <a href="https://www.forbes.com/sites/forbesagencycouncil/2021/05/12/who-listens-to-podcasts-and-how-can-you-reach-them/?sh=3ad0c4583e84">shareable</a> clips to 10x the engagement on your podcast across your social channels. </p>
          <a href="mailto:tasslexyz@gmail.com?subject=Interested in using Tassle!" class="try_now new_link">Request a demo</a>
        </div>
        <div className="footer">
            <div className="container d-flex">
              <div className="col-6">
                <img src={footerlogo} />
              </div>
              <div className="col-6" style={{textAlign:"right"}}>
                <img src={facebook}  style={{marginRight:"20px"}}/>
                <img src={twitter} />
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default HowItWorks;
