import React, { Component } from "react";

import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import ProjectsList from "./components/projectsList";
import Signup from "./components/signup";
import { ProtectedRoute } from "./components/protectedRoute";
import ClipList from "./components/clipList";
import SrtEdit from "./components/srtEdit";
import Navbar from "./components/navbar";
import HomePage from "./components/homepage";
import "./assets/css/fontgen.css";
import "./assets/css/projectlist.css";
import "./assets/css/utils.css";
import NewProject from "./components/newProjectPage";
import AudioUpload from "./components/audioUpload";
import VideoUpload from "./components/videoUpload";
import VideoTrial from "./components/videoTrial";
import AfterUpload from "./components/afterUpload";
import HowItWorks from "./components/howitworks";
import Login from "./components/login";

class App extends Component {
  state = {};
  render() {
    return (
      <div
        style={{ background: "white", height: "100vh", overflowX: "hidden" }}
      >
        <ReactNotification />
        <BrowserRouter>
          <Navbar />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/signup" component={Signup}></Route>
            <Route exact path="/login" component={Login}></Route>
            <ProtectedRoute
              exact
              path="/uploadSuccess"
              component={AfterUpload}
            />
            <ProtectedRoute
              exact
              path="/projectList"
              component={ProjectsList}
            />
            <Route exact path="/newProject" component={NewProject} />
            <ProtectedRoute exact path="/audioUpload" component={AudioUpload} />
            <Route exact path="/videoUpload" component={VideoUpload} />
            <Route exact path="/howitworks" component={HowItWorks} />
            <ProtectedRoute exact path="/trial" component={VideoTrial} />
            <ProtectedRoute
              exact
              path="/clipList/:project_id"
              component={ClipList}
            />
            <ProtectedRoute exact path="/srtEdit" component={SrtEdit} />
            <Route exact path="/test" component={VideoTrial} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;

//class App extends Component {
// async componentDidMount() {
//   this.unsubscribeFromAuth = auth.onAuthStateChanged(async (user) => {
//     await this.setState({ currentUser: user });
//   });
// }

// componentWillUnmount() {
//   console.log(this.state);
//   this.unsubscribeFromAuth();
// }
// render() {
//   return (
//     <div>
//       {this.state.currentUser === null ? (
//         <button
//           onClick={async () => {
//             var userAuth = await auth
//               .createUserWithEmailAndPassword(
//                 "jishanta@gmail.com",
//                 "abcd@123ABCD@123"
//               )
//               .catch((err) => alert(err.message));
//             // firebase
//             //   .database()
//             //   .ref("users/" + userAuth.user.uid)
//             //   .set({
//             //     email: userAuth.user.email,
//             //     password: userAuth.user.uid,
//             //   })
//             //   .catch((err) => console.log(err.message));
//             // var userAuth = await auth.signInWithEmailAndPassword(
//             //   "jishanta@gmail.com",
//             //   "abcd@123ABCD@123"
//             // );
//             // console.log(userAuth.user.uid);
//           }}
//         >
//           Login with Google
//         </button>
//       ) : (
//         <div>
//           <img src={this.state.currentUser.photoURL} alt={""}></img>
//           <button onClick={() => auth.signOut()}>Log Out</button>
//         </div>
//       )}
//     </div>
//   );
// }

// }

// export default App;
