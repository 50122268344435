import React, { Component } from "react";
import model_info1 from "../assets/images/model_info1.svg";
import model_info2 from "../assets/images/model_info2.svg";
import model_info3 from "../assets/images/model_info3.svg";
import model_info1_pt2 from "../assets/images/model_info1_pt2.svg";
import model_info2_pt2 from "../assets/images/model_info2_pt2.svg";
import model_info3_pt2 from "../assets/images/model_info3_pt2.svg";
import side_arrow from "../assets/images/side_arrow.svg";
import poster1 from "../assets/images/v1_poster.png";
import poster2 from "../assets/images/v2_poster.png";
import poster3 from "../assets/images/v3_poster.png";
import footerlogo from "../assets/images/footer-logo.svg";
import facebook from "../assets/images/facebook.svg";
import twitter from "../assets/images/twitter.svg";
import right_arrow from "../assets/images/right_arrow_icon.svg";
import uploadvideo from "../assets/images/uploadvideo.gif";
import emailbox from "../assets/images/emailboxicon.svg";
import Curated from "../assets/images/Curated.svg";
import SaveTime from "../assets/images/Save Time.svg";
import Subtitles from "../assets/images/Subtitles.svg";
import Custombackground from "../assets/images/Custom-background.svg";
import teenyicons_mp3outline from "../assets/images/teenyicons_mp3-outline.svg";
import Support from "../assets/images/Support.svg";
import Signup from "./signup";
import Modal from "./modal";
import eventBus from "./EventBus";
import auth from "../services/auth";
import { analytics } from "../services/firebase";
import mixpanel from "../services/mixpanel";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      showModal: false,
    };
    this.signup = React.createRef();
    if (auth.isAuthenticated()) {
      this.props.history.replace("/projectList");
    }
  }
  componentDidMount() {
    console.log(this.state);
    mixpanel.track("Landing_Page_Viewed");
    eventBus.on("videoApply", (datas) =>
      this.setState({ showModal: datas.trigger })
    );
  }

  componentWillUnmount() {
    eventBus.remove("videoApply");
  }
  handleCTA = () => {
    this.signup.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    window.gtag("event", "conversion", {
      send_to: "AW-319685830/HQGDCKmpjo4DEMaJuJgB",
    });
    analytics.logEvent("CTA_clicked");
    mixpanel.track("CTA_Clicked");
  };
  updateInputValue = (evt) => {
    this.setState({
      inputValue: evt.target.value,
    });
  };
  handleemailtry = () => {
    analytics.logEvent("CTA_clicked");
    mixpanel.track("CTA_Clicked");
    if (this.state.inputValue != "") {
      mixpanel.track("entered_business_email");
    }
    mixpanel.track("entered_business_email");
    this.setState({ showModal: !this.state.showModal });
    this.props.history.datavaule = this.state.inputValue;
    // if (this.state.inputValue != "") {
    //   mixpanel.track("entered_business_email");
    //   this.setState({ showModal: !this.state.showModal });
    //   this.props.history.datavaule = this.state.inputValue;
    // } else {
    //   this.props.history.replace("/newProject");
    // }
  };
  render() {
    return (
      <div className="container-fluid" ref={this.signup}>
        <div className="row vert-center">
          <div className="grad-custom" style={{ height: "150px" }}></div>
          <div className="col-md-4 col-12">
            <div className="col-xl-9 col-md-12 shareable-podcast">
              <h1
                className="heading"
                style={{ fontSize: "36px", lineHeight: "54px" }}
              >
                Get shareable podcast highlights in minutes
              </h1>
              <p
                style={{
                  color: "#939393",
                  font: 500,
                  fontSize: "18px",
                  marginBottom: "0px",
                }}
              >
                Go from recording your podcast to posting on
                Twitter/Instagram/TikTok in 30 minutes or less!
              </p>
              <div
                className="col-12 flex-center-row Start-your"
                style={{ marginTop: "30px" }}
              >
                <div className="emailsubcribe">
                  {/* <input
                    type="email"
                    value={this.state.inputValue}
                    onChange={(evt) => this.updateInputValue(evt)}
                    placeholder="Enter your business email"
                  />
                  <img src={emailbox} alt="email-box" /> */}
                  <a
                    href="https://krish111748.typeform.com/to/XPQKOPIl"
                    className="try_now new_link"
                    onClick={this.handleCTA}
                  >
                    Try for free
                    {/* Upload your project now <img src={right_arrow} /> */}
                  </a>
                </div>
              </div>
              {/* <p
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                  color: "rgba(103, 103, 103, 1)",
                }}
              >
                90 day trial. No credit card required
              </p> */}
            </div>
          </div>
          <div className="col-md-7 col-12 video-gif">
            <div className="m-auto col-xl-8 col-md-12">
              <img src={uploadvideo} alt="videoupload" width="100%" />
            </div>
          </div>
          {/* <div className="col-lg-7">
            <div className="head-container">
              <h1 className="heading"> */}
          {/* Get <span style={{ color: "#7E1FDB" }}>automatic</span> <br /> */}
          {/* Get automatic <br />
                <span
                  className="login-box"
                  style={{
                    borderRadius: "0",
                    padding: "6px",
                    color: "white",
                  }}
                >
                  highlights
                </span>{" "}
                <br /> for your podcast <br />
                <span className="mins-less" style={{ color: "#E85F64" }}>in 30 mins or less!</span>
              </h1>
              <h2 className="home-sub">
              Save the hassle, Just use tassle
              </h2>
            </div>
          </div> */}
          {/* <div
            className="col-lg-5"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}>
            <Signup />
          </div> */}
        </div>
        <div className="not-yourself row">
          <div className="col-md-8">
            <h3>
              Stretch your content,
              <br /> not yourself
            </h3>
          </div>
          <div className="col-md-4">
            <p>
              Stop spending hours in post-production: reviewing your footage to
              find the perfect highlight clip for social media. Use Tassle, and
              10x your output in 10 minutes.
            </p>
          </div>
        </div>
        <div className="how-does-work">
          <div className="row" style={{ width: "80%", margin: "auto" }}>
            <div className="col-lg-12">
              <h1
                className="heading text-center"
                style={{ fontSize: "36px", lineHeight: "54px" }}
              >
                How does it work?
              </h1>
            </div>
            <div
              className="col-lg-3 mb-3 model-image-size"
              style={{ margin: "auto" }}
            >
              <img src={model_info1_pt2} width="100%"></img>
            </div>
            <div className="col-lg-1 flex-center-row hide-below">
              <img src={side_arrow} width="70%"></img>
            </div>
            <div
              className="col-lg-3 mb-3 model-image-size"
              style={{ margin: "auto" }}
            >
              <img src={model_info2_pt2} width="100%"></img>
            </div>
            <div className="col-lg-1 flex-center-row hide-below">
              <img src={side_arrow} width="70%"></img>
            </div>
            <div
              className="col-lg-3 mb-3 model-image-size"
              style={{ margin: "auto" }}
            >
              <img src={model_info3_pt2} width="100%"></img>
            </div>
          </div>
        </div>
        <div className="row" style={{ width: "100%", margin: "auto" }}>
          <div className="col-lg-12">
            <h1
              className="heading text-center"
              style={{ fontSize: "36px", lineHeight: "54px" }}
            >
              What kind of clips can you create ?
            </h1>
          </div>
          <div style={{ padding: "40px" }}></div>
          <div className="row p-0 testimony-main">
            <div className="testimony-box col-lg-4 col-12 mb-3">
              <iframe
                width="100%"
                height="280"
                src="https://www.youtube.com/embed/ERSFRCBwZ5Y"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div style={{ marginTop: "35px", textAlign: "center" }}>
                Videograms with a background image
              </div>
              {/* <video controls className="image mb-3" poster={poster1}>
                  <source
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/pathrise-convert-1606954137718.appspot.com/o/video1.mp4?alt=media&token=7f9f6308-81fa-4abe-a819-b4499ae8a6ff"
                    }
                    type="video/mp4"
                  ></source>
                </video> */}
            </div>
            <div className="testimony-box col-lg-4 col-12 mb-3">
              <iframe
                width="100%"
                height="280"
                src="https://www.youtube.com/embed/P7caih7qnFg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div style={{ marginTop: "35px", textAlign: "center" }}>
                Videograms
              </div>
              {/* <video controls className="image mb-3" poster={poster2}>
                  <source
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/pathrise-convert-1606954137718.appspot.com/o/video2.mp4?alt=media&token=187489c7-922c-4bca-bb07-71056f549e41"
                    }
                    type="video/mp4"
                  ></source>
                </video> */}
            </div>
            <div className="testimony-box col-lg-4 col-12 mb-3">
              <iframe
                width="100%"
                height="280"
                src="https://www.youtube.com/embed/RIPCyi3zPqo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div style={{ marginTop: "35px", textAlign: "center" }}>
                Audiograms
              </div>
              {/* <video controls className="image mb-3" poster={poster3}>
                  <source
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/pathrise-convert-1606954137718.appspot.com/o/video3.mp4?alt=media&token=dd3b1559-9325-4dcf-9199-76c3728ac455"
                    }
                    type="video/mp4"
                  ></source>
                </video> */}
            </div>
            {/* <p style={{ textAlign: "center" }}>
              90 day trial. No credit card required
            </p> */}
            {/* <div style={{ padding: "40px" }}></div>
          <div className="col-12 flex-center-row">
            <div className="try_now new_link" onClick={this.handleCTA}>
              Try it now 🎉
            </div>
          </div> */}
          </div>
          <div className="row">
            <div className="features" style={{ width: "100%" }}>
              <div className="col-lg-12">
                <h1
                  className="heading text-center"
                  style={{ fontSize: "36px", lineHeight: "54px" }}
                >
                  Features
                </h1>
              </div>
              <div class="row">
                <div class="service col-lg-4 col-md-6 col-12">
                  <img src={Curated} alt="Curated" />
                  <h2>Curated</h2>
                  <p>
                    Our AI algorithms curate shareable clips from your content.
                  </p>
                </div>
                <div class="service col-lg-4 col-md-6 col-12">
                  <img src={SaveTime} alt="SaveTime" />
                  <h2>Save Time</h2>
                  <p>
                    It takes just 5-6 minutes to go from reviewing our clips to
                    posting them on social media.
                  </p>
                </div>
                <div class="service col-lg-4 col-md-6 col-12">
                  <img src={Subtitles} alt="Subtitles" />
                  <h2>Subtitles automatically burned in</h2>
                  <p>All clips come overlaid with adjustable subtitles.</p>
                </div>
                <div class="service col-lg-4 col-md-6 col-12">
                  <img src={Custombackground} alt="Custombackground" />
                  <h2>Custom background</h2>
                  <p>
                    Upload unique background images for your audio/video
                    content.
                  </p>
                </div>
                <div class="service col-lg-4 col-md-6 col-12">
                  <img
                    src={teenyicons_mp3outline}
                    alt="teenyicons_mp3outline"
                  />
                  <h2>Multiple Filetypes</h2>
                  <p>
                    Support for mp3 & mp4 recordings (if it's mp3, we'll
                    generate the waveform clips for you 😊).
                    <br />
                    <br />
                    Missing one you need? let us know and we’ll add it.
                  </p>
                </div>
                <div class="service col-lg-4 col-md-6 col-12">
                  <img src={Support} alt="Support" />
                  <h2>Original Timestamps</h2>
                  <p>
                    Clip cut off just before something good? No problem, Tassle
                    shows you the original timestamps to let you grab the whole
                    idea yourself.
                  </p>
                </div>
              </div>
              <div
                className="col-12 flex-center-row Start-your"
                style={{ marginTop: "50px" }}
              >
                <a
                  href="https://krish111748.typeform.com/to/XPQKOPIl"
                  className="try_now new_link"
                  onClick={this.handleCTA}
                >
                  Try for free <img src={right_arrow} />
                </a>
              </div>
              {/* <p style={{ textAlign: "center" }}>
                90 day trial. No credit card required
              </p> */}
              {/* <div style={{ padding: "40px" }}></div>
          <div className="col-12 flex-center-row">
            <div className="try_now new_link" onClick={this.handleCTA}>
              Try it now 🎉
            </div>
          </div> */}
            </div>
          </div>
          {/*<div style={{ width: "80%", margin: "auto" }} className="row pricing-table">
          <div className="col-lg-12">
            <h1
              className="heading text-center"
              style={{ fontSize: "36px", lineHeight: "54px" }}
            >
              Features
            </h1>
            {/* <div
              className="pricing-per-month"
              style={{ fontSize: "64px", textAlign: "center" }}
            >
              $7.99 <span style={{ fontSize: "24px" }}>per month</span>
            </div> */}
        </div>
        {/*<div className="flex-center-row pricing-information">
            <ul>
              <li>Process up to 5 hours of audio/video content per month</li>
              <li>No watermarks</li>
              <li>Subtitles automatically burned in</li>
              <li>Stored files and fully editable videos forever</li>
              <li>
                Support for mp3 & mp4 recordings (if it's mp3, we'll generate
                the waveform clips for you 😊){" "}
              </li>
              <li>
                Support for batch uploads (get clips for multiple episodes, at
                once)
              </li>
              <li>Custom logo + background image support</li>
            </ul>
          </div>
           <div className="col-lg-12 flex-center-row">
            <h3 className="pricing-button">
              <span style={{ textDecoration: "line-through" }}>$7.99</span>{" "}
              <span style={{ fontWeight: "700", fontFamily: "Nunito-regular" }}>
                Free
              </span>
            </h3>
          </div>
          <div style={{ padding: "10px" }}></div>
          <div className="col-lg-12 flex-center-row">
            <h3 className="pricing-info">
              Our focus is on{" "}
              <span style={{ fontFamily: "Nunito-bold" }}>
                creating value + collecting feedback.
              </span>{" "}
              Everyone who signs up today, gets a{" "}
              <span style={{ borderBottom: "5px solid #00CA81" }}>
                3 month free trial.
              </span>
            </h3>
          </div>
          <div style={{ padding: "40px" }}></div> */}
        {/* <div className="flex-center-row" style={{marginTop:'50px'}}>
            <a
              href="/newProject"
              className="try_now new_link"
              onClick={this.handleCTA}
            >
              Get started <img src={right_arrow} />
            </a>
          </div>
          <div style={{ padding: "80px" }}></div>
          <h1 className="gray-color" style={{ width: "100%" }}>
            Have questions / thoughts/ feedback?{" "}
            <a
              style={{ textDecoration: "underline" }}
              className="gray-color"
              href="mailto:tasslexyz@gmail.com?subject=Interested%20in%20using%20Tassle!%20"
            >
              Contact Us
            </a>
          </h1>
          <div style={{ padding: "20px" }}></div> 
        </div>*/}
        <div className="talk-us-now row">
          <h2>
            Your podcast can grow with Tassle.
            <br />
            <span>Talk to us now.</span>
          </h2>
          <p>
            Use{" "}
            <a href="https://www.forbes.com/sites/forbesagencycouncil/2021/05/12/who-listens-to-podcasts-and-how-can-you-reach-them/?sh=3ad0c4583e84">
              shareable
            </a>{" "}
            clips to 10x the engagement on your podcast across your social
            channels.{" "}
          </p>
          <a
            href="mailto:tasslexyz@gmail.com?subject=Interested in using Tassle!"
            class="try_now new_link"
          >
            Request a demo
          </a>
        </div>
        <div className="footer">
          <div className="container d-flex">
            <div className="col-6">
              <img src={footerlogo} />
            </div>
            <div className="col-6" style={{ textAlign: "right" }}>
              <img src={facebook} style={{ marginRight: "20px" }} />
              <img src={twitter} />
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showModal}
          toggleModalDisplay={this.toggleModalDisplay}
          close={() => this.setState({ ...this.state, showModal: false })}
        />
      </div>
    );
  }
}

export default HomePage;
