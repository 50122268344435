import React, { Component } from "react";
import { store } from "react-notifications-component";
import firebase, { auth, return_notification } from "../services/firebase";
import Auth from "../services/auth";
import mixpanel from "../services/mixpanel";
class AfterUpload extends Component {
  state = {};

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="afterUpload-Heading col-lg-12 mb-2">
            Thank you for using Tassle! We will email you when your clips are
            ready. You can expect your clips to be done in next 30-45 mins.
          </div>
          <div className="afterUpload-Sub-heading col-lg-12 mb-2">
            Have any questions? Want to give feedback? Join us on
            <a
              href="https://discord.gg/r8t79yTgSZ"
              style={{ textDecoration: "none", color: "#7e1fdb" }}
              onClick={() => {
                mixpanel.track("Discord Button Clicked");
              }}
            >
              Discord
            </a>
          </div>
          <div
            className="col-lg-12 mb-2 afterUpload-Sub-heading"
            style={{ fontSize: "18px" }}
          >
            Enjoy the experience? give us a shoutout on Instagram/Twitter
            @tassle_xyz 😊
          </div>
        </div>
      </div>
    );
  }
}

export default AfterUpload;
