import firebase from "firebase";
import "firebase/auth";
import mixpanel from "../services/mixpanel";
import Auth from "../services/auth";
import { store } from "react-notifications-component";
import Cohere from "cohere-js";

// const firebaseConfig = {
//   apiKey: "AIzaSyDZdIwEectJCPDZG0vHDUF5bR1u7n7qmiM",
//   authDomain: "testvideoto.firebaseapp.com",
//   projectId: "testvideoto",
//   storageBucket: "testvideoto.appspot.com",
//   messagingSenderId: "160569792086",
//   appId: "1:160569792086:web:fca2124dfe1c8449294df7",
//   measurementId: "G-4J80R8PMY6",
// };

const firebaseConfig = {
  apiKey: "AIzaSyDIBWJybPFI0RJyiohZWtJrXXZdbdRZN78",
  authDomain: "pathrise-convert-1606954137718.firebaseapp.com",
  databaseURL:
    "https://pathrise-convert-1606954137718-default-rtdb.firebaseio.com",
  projectId: "pathrise-convert-1606954137718",
  storageBucket: "pathrise-convert-1606954137718.appspot.com",
  messagingSenderId: "510528649030",
  appId: "1:510528649030:web:acd6b2a6d3d7182c541747",
  measurementId: "G-HPGJPK9RZM",
};

firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export default firebase;

export const return_notification = (title, message, type) => {
  return {
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "bottom-left",
    animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
    animationOut: ["animate__animated animate__fadeOut"], // `animate.css v4` classes
    dismiss: {
      duration: 2000,
    },
    isMobile: true,
  };
};
