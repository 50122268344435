import React, { Component } from "react";
import Loader from "react-loader";
import ReactPlayer from "react-player";
import { store } from "react-notifications-component";
import firebase, { return_notification, analytics } from "../services/firebase";
import Auth from "../services/auth";
import mixpanel from "../services/mixpanel";
const webvtt = require("node-webvtt");
var FileSaver = require("file-saver");
const moment = require("moment");
const axios = require("axios").default;
class SrtEdit extends Component {
  state = {
    srtData: "",
    loaded: false,
    srtParsed: false,
    srtArr: [],
    currTimeStart: "",
    currTimeEnd: "",
    currText: "",
    currId: "",
    temp: [],
    videoUrl: "",
    subtitleUrl: "",
    loadError: false,
    clipStartTime: "0",
    clipEndTime: "0",
  };
  componentDidMount() {
    var that = this;
    var clip = this.props.location.state["clip_id"];
    var project_id = this.props.location.state["project_id"];
    var uid = Auth.getUID();
    mixpanel.track("clip edit page opened", {
      project_id: this.props.location.state["project_id"],
      clip_id: this.props.location.state["clip_id"],
    });
    firebase
      .database()
      .ref()
      .child("users/" + uid + "/projects/" + project_id + "/clips/" + clip)
      .on("value", async (snapshot) => {
        if (snapshot.toJSON() === null) {
          console.log("EMPTY");
        } else {
          let data = snapshot.toJSON();
          await this.setState({
            clipStartTime: this.timestampToDate(data.start),
            clipEndTime: this.timestampToDate(data.end),
          });
          console.log("UPDATED STATE", this.state);
        }
      });
    axios
      .get(
        "https://us-central1-pathrise-convert-1606954137718.cloudfunctions.net/getSignedUrl",
        {
          headers: {
            filename: uid + "/" + project_id + "/" + clip + "/" + "clip.mp4",
          },
        }
      )
      .then((resp) => {
        this.setState({
          videoUrl: resp.data.url,
        });
      })
      .catch((er) => {
        mixpanel.log("clip_load_error");
        console.log("Error in loading the video url");
        console.log(er);
      });
    axios
      .get(
        "https://us-central1-pathrise-convert-1606954137718.cloudfunctions.net/getSignedUrl",
        {
          headers: {
            filename:
              uid + "/" + project_id + "/" + clip + "/" + "subtitle.vtt",
          },
        }
      )
      .then((resp) => {
        axios
          .get(resp.data.url)
          .then(async (val) => {
            console.log(val.data);
            await this.setState({
              subtitleUrl: resp.data.url,
              srtData: val.data,
              loaded: true,
            });
            var bodyFormData = new FormData();
            bodyFormData.append(
              "video_file_path",
              uid + "/" + project_id + "/" + clip + "/" + "clip.mp4"
            );
            //var f = new File(String(val.data), "subtitlefile.vtt");
            var blob = new Blob([val.data], { type: "text/plain" });
            bodyFormData.append("subtitle_file", blob);
            this.handleSubtitleUpload({});
          })
          .catch(function (err) {
            mixpanel.log("clip_load_error");
            that.setState({
              loadError: true,
            });
            that.showAlert("Subtitle loading error");
            that.props.history.goBack();
            console.log("error inside api call");
          });
      })
      .catch((err) => {
        console.log("Error in getting subtitle file");
        console.log(err);
      });
  }

  timestampToDate(unix_timestamp) {
    var seconds = Math.floor((unix_timestamp / 1000) % 60),
      minutes = Math.floor((unix_timestamp / (1000 * 60)) % 60),
      hours = Math.floor((unix_timestamp / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  }

  jSONtoStringConverter = (data) => {
    let returnStr = JSON.stringify(data);
    return returnStr;
  };
  handleSubtitleUpload = (event) => {
    // let reader = new FileReader();
    // reader.readAsText(this.state.srtData);
    //console.log(parseSRT(this.state.srtData));
    //var srt = parseSRT(this.state.srtData);
    console.log(this.state.srtData);
    const srt = webvtt.parse(this.state.srtData)["cues"];
    var srtArray = [];
    for (var s in srt) {
      //console.log(srt[s]['start']);
      srt[s]["identifier"] = parseInt(srt[s]["identifier"]) - 1;
      srt[s]["id"] = parseInt(srt[s]["identifier"]);
      srt[s]["start"] = this.convertHMS(Number(srt[s]["start"]));
      srt[s]["end"] = this.convertHMS(Number(srt[s]["end"]));
      srtArray.push(srt[s]);
    }

    //console.log(srtArray);
    this.setState({
      loaded: true,
      srtParsed: true,
      srtArr: srtArray,
    });
    // reader.onload = (e) => {
    //   console.log(parseSRT(e.target.result));

    //   // setSubtitleObj(jSONtoStringConverter(parseSRT(e.target.result)));
    // };
  };
  setLoading = (val) => {
    this.setState({
      loaded: val,
    });
  };
  compare = (a, b) => {
    if (a.start < b.start) return -1;
    if (a.start > b.start) return 1;
    return 0;
  };
  downloadFile = (filename, text) => {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };
  handleCompile = () => {
    //console.log("request compile sent");
    analytics.logEvent("compile_btn_clicked");
    mixpanel.track("compile_btn_clicked", {
      project_id: this.props.location.state["project_id"],
      clip_id: this.props.location.state["clip_id"],
    });
    var formData = new FormData();
    var blob = new Blob(
      [
        webvtt.compile({
          valid: true,
          cues: this.state.srtArr,
          strict: true,
        }),
      ],
      { type: "text/vtt" }
    );
    formData.append("subtitle_file", blob, "subtitle.vtt");
    var clip = this.props.location.state["clip_id"];
    var project_id = this.props.location.state["project_id"];
    var uid = Auth.getUID();
    formData.append("user_id", uid);
    formData.append("project_id", project_id);
    formData.append("clip_id", clip);
    this.showAlert("File Compilation Started, Please Wait");
    this.setLoading(false);
    axios
      .post("https://api.tassle.xyz/video-subtitle-burner", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const pollingRequestId = response.data.id;
        console.log("Polling request id: ", pollingRequestId);
        const intervalId = setInterval(async () => {
          console.log("calling get status");
          axios
            .get(
              `https://api.tassle.xyz/video-subtitle-burner/${pollingRequestId}`
            )
            .then(async (pollingResponse) => {
              if (
                pollingResponse.data.status === "in_progress" ||
                pollingResponse.data.status === "in_queue"
              )
                return;
              if (pollingResponse.data.status === "completed") {
                clearInterval(intervalId);

                await axios({
                  url: pollingResponse.data.result.url,
                  method: "GET",
                  responseType: "blob",
                }).then((response) => {
                  console.log("Signed URL request response: ", response);
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "clip" + clip + ".mp4");
                  document.body.appendChild(link);
                  link.click();
                  this.setLoading(true);
                  analytics.logEvent("compile_successfully_completed");
                  mixpanel.track("compile_successfully_completed", {
                    project_id: this.props.location.state["project_id"],
                    clip_id: this.props.location.state["clip_id"],
                  });
                });
              } else if (pollingResponse.data.status === "failed") {
                clearInterval(intervalId);
                throw Error(pollingResponse.data.result.message);
              }
            })
            .catch((err) => {
              console.log(err);
              this.setLoading(true);
            });
        }, 20000);
      })
      .catch((err) => {
        console.log(err);
        this.setLoading(true);
      });
  };
  handleLineClick = (index) => (e) => {
    // console.log("line click called");
    // console.log(this.state.srtArr[index]);
    var curr = this.state.srtArr[index];
    this.setState({
      currTimeStart: curr["start"],
      currTimeEnd: curr["end"],
      currText: curr["text"],
      currId: curr["identifier"],
    });
  };

  convertHMS = (value) => {
    const sec = parseInt(value, 10);
    let hours   = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - (hours * 3600)) / 60);
    let seconds = sec - (hours * 3600) - (minutes * 60);
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
  }

  handleChange = (e) => {
    if (e.target.value === "") {
      this.setState({
        [e.target.name]: e.target.value,
      });
      return;
    }
    if (e.target.name == "currTimeStart" || e.target.name == "currTimeEnd") {
      console.log(e.target.value);
      console.log(e.target.value.slice(0, -1));
      console.log(e.target.value.includes("."));
      if (e.target.value.slice(-1) == ".") {
        console.log("Decimal entered");
        if (e.target.value.slice(0, -1).includes(".")) {
          this.showAlert("Multiple Decimal points not allowed");
          return;
        }
        this.setState({
          [e.target.name]: e.target.value,
        });
        return;
      }
      if (isNaN(parseFloat(e.target.value.slice(-1)))) {
        //console.log("here not float");
        this.showAlert("Only Float numbers allowed");
        return;
      }
      this.setState({
        [e.target.name]: e.target.value,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };
  showAlert = (msg, info = " ", type = "warning") => {
    store.addNotification({ ...return_notification(msg, info, type) });
  };
  handleSave = (e) => {
    // console.log(this.state.currId);
    // console.log(0 == "");
    if (
      JSON.stringify(this.state.currId) === "" ||
      this.state.currId === null ||
      this.state.currId === ""
    ) {
      //console.log("reached here");
      //this.showAlert("Id NULL cant save try Add new instead!");
      this.handleAddNew({});
      return;
    }
    // console.log("check if curr id is null or not");
    var curr = {};
    curr["currTimeStart"] = this.state.currTimeStart;
    curr["currTimeEnd"] = this.state.currTimeEnd;
    curr["currText"] = this.state.currText;
    var tempArr = [...this.state.srtArr];
    tempArr.splice(this.state.currId, 1);
    var checkStatus = this.verifyInsert(
      curr["currTimeStart"],
      curr["currTimeEnd"],
      tempArr
    );
    if (checkStatus[0] == false) {
      this.showAlert("Time Clash at id : " + checkStatus[1]);
      return;
    }
    var arr = this.state.srtArr;
    arr[this.state.currId]["start"] =
      curr["currTimeStart"] == null
        ? arr[this.state.currId]["start"]
        : parseFloat(curr["currTimeStart"]);
    arr[this.state.currId]["end"] =
      curr["currTimeEnd"] == null
        ? arr[this.state.currId]["end"]
        : parseFloat(curr["currTimeEnd"]);
    arr[this.state.currId]["text"] =
      curr["currText"] == null
        ? arr[this.state.currId]["text"]
        : curr["currText"];
    arr.sort(this.compare);
    arr = this.updateIdentifier(arr);
    // this.setState({
    //   srtArr: arr,
    // });
    this.setState({
      srtArr: arr,
      currId: "",
      currText: "",
      currTimeEnd: "",
      currTimeStart: "",
    });
  };
  hanleDelete = (index) => (e) => {
    index = parseInt(index);
    var arr = this.state.srtArr;
    var delArr = this.state.temp;
    var slice1 = arr.slice(0, index);
    var slice2 = arr.slice(index + 1, arr.length);
    slice1.push(...slice2);
    //console.log(slice1);
    delArr.push(arr[index]);
    this.updateIdentifier(slice1);
    this.setState({
      temp: delArr,
      srtArr: slice1,
    });
    this.setState({
      currId: "",
      currText: "",
      currTimeEnd: "",
      currTimeStart: "",
    });
  };
  updateIdentifier = (arr) => {
    for (var ind in arr) {
      arr[ind]["identifier"] = ind;
      arr[ind]["id"] = ind;
    }
    return arr;
  };
  verifyInsert = (start, end, arr) => {
    start = parseFloat(start);
    end = parseFloat(end);
    for (var ind in arr) {
      var curr = arr[ind];
      if (start == curr["start"] && end == curr["end"]) {
        return [false, ind];
      }
      if (
        (curr["start"] > start && curr["start"] < end) ||
        (curr["end"] > start && curr["end"] < end) ||
        (start > curr["start"] && start < curr["end"]) ||
        (end > curr["start"] && end < curr["end"])
      ) {
        return [false, ind];
      }
    }
    return [true, null];
  };
  handleAddNew = (e) => {
    var allDataCorrect = true;
    var [start, end, text] = [
      parseFloat(this.state.currTimeStart),
      parseFloat(this.state.currTimeEnd),
      this.state.currText,
    ];
    // console.log(start, end, text);
    if (start > end) {
      // console.log(start, end);
      this.showAlert("Start > end");
      allDataCorrect = false;
      return;
    }
    if (start == null || end == null || text == null) {
      this.showAlert("All Fields are required");
      allDataCorrect = false;
      return;
    }
    var checkStatus = this.verifyInsert(start, end, this.state.srtArr);
    if (checkStatus[0] == false) {
      this.showAlert("Time Clash at id : " + checkStatus[1]);
      return;
    }
    if (allDataCorrect) {
      var arr = this.state.srtArr;
      var newId = arr.length;
      arr.push({
        id: newId,
        identifier: newId,
        start: parseFloat(start),
        end: parseFloat(end),
        text: text,
        styles: "",
      });
      arr.sort(this.compare);
      arr = this.updateIdentifier(arr);
      this.setState({
        srtArr: arr,
        currId: "",
        currText: "",
        currTimeEnd: "",
        currTimeStart: "",
      });
    } else {
      this.showAlert("Couldn't add new");
    }
  };
  render() {
    var items = [];
    var deleted_items = [];
    if (this.state.srtParsed) {
      var temp = this.state.temp;
      if (temp != [] && temp.length != 0) {
        for (var ele in temp) {
          deleted_items.push(
            <div
              className="row mb-2"
              style={{ fontFamily: "Nunito-Bold", fontSize: "14px" }}
            >
              <div className="col-2 font-sizer">{temp[ele]["identifier"]}</div>
              <div className="col-2 font-sizer">{temp[ele]["start"]}</div>
              <div className="col-2 font-sizer">{temp[ele]["end"]}</div>
              <div className="col-6 font-sizer">{temp[ele]["text"]}</div>
            </div>
          );
        }
      }
      var srtArray = this.state.srtArr;
      for (var ele in srtArray) {
        items.push(
          <div>
            <div id={ele}>
              <div
                className="row"
                style={{
                  fontFamily: "Nunito-Bold",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                <div className="col-2 font-sizer">
                  {srtArray[ele]["identifier"]}
                </div>
                <div className="col-1 font-sizer">{srtArray[ele]["start"]}</div>
                <div className="col-1 font-sizer">{srtArray[ele]["end"]}</div>
                <div className="col-4 font-sizer">{srtArray[ele]["text"]}</div>
                <div className="col-2 font-sizer">
                  <button
                    onClick={this.handleLineClick(ele)}
                    className="srtEditButton mb-2"
                  >
                    {" "}
                    Edit{" "}
                  </button>
                </div>
                <div className="col-2">
                  <button
                    onClick={this.hanleDelete(ele)}
                    className="srtDelButton mb-2"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
    var status = [];
    if (this.state.currId == "") {
      status = [];
      status.push(<div>Adding new Sub</div>);
    } else {
      status = [];
      status.push(<div>Changing at id : {this.state.currId}</div>);
    }
    return (
      <div>
        <Loader loaded={this.state.loaded}>
          <div
            style={{
              height: "100vh",
              overflowX: "hidden",
              overflowY: "auto",
            }}
            className="srtMain"
          >
            <div className="row">
              <div
                className="col-lg-6"
                style={{
                  overflowX: "hidden",
                  overflowY: "auto",
                  height: "100%",
                }}
              >
                <div className="row d-flex justify-content-between">
                  <div className="col-lg-6">
                    <h1
                      className="m-3"
                      style={{ fontSize: "36px", fontFamily: "Nunito-Bold" }}
                    >
                      <div className="text-left">
                        Clip{" "}
                        {parseInt(this.props.location.state["clip_id"]) + 1}
                      </div>
                    </h1>
                  </div>
                  <div
                    className="col-lg-6"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <p className="instruction-text">
                      {this.state.clipStartTime != "0"
                        ? this.state.clipStartTime
                        : "Loading"}
                      -
                      {this.state.clipEndTime != "0"
                        ? this.state.clipEndTime
                        : "Loading"}
                    </p>
                  </div>
                </div>
                <div className="videoPlayer" style={{ height: "60%" }}>
                  <ReactPlayer
                    onError={() => {
                      console.log("react player loading error");
                      mixpanel.log("clip_load_error");
                    }}
                    controls
                    height={"350px"}
                    width={"700px"}
                    url={this.state.videoUrl}
                    config={{
                      file: {
                        attributes: {
                          crossOrigin: "true",
                        },
                        tracks: [
                          {
                            kind: "subtitles",
                            src: this.state.subtitleUrl,
                            srcLang: "en",
                            default: true,
                          },
                        ],
                      },
                    }}
                  />
                </div>
                <br />
                <div
                  className="p-3"
                  style={{
                    height: "50%",
                    width: "85%",
                    margin: "auto",
                    textAlign: "center",
                    border: "1px solid gray",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    className="row"
                    style={{ background: "", margin: "auto" }}
                  >
                    <div className="col-lg-6 mb-2">
                      <span
                        className="col-auto label-text"
                        style={{ width: "50%" }}
                      >
                        Start Time{" "}
                      </span>
                      <input
                        type={"text"}
                        className="col-auto srtinput"
                        onChange={this.handleChange}
                        name={"currTimeStart"}
                        value={this.state.currTimeStart}
                      ></input>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <span className="col-auto label-text">End Time </span>
                      <input
                        className="col-auto srtinput"
                        type={"text"}
                        onChange={this.handleChange}
                        name={"currTimeEnd"}
                        value={this.state.currTimeEnd}
                      ></input>
                    </div>
                    <div className="col-lg-12 mb-2">
                      <span className="label-text">Text </span>
                      <input
                        type={"text"}
                        className="srtinput"
                        onChange={this.handleChange}
                        name={"currText"}
                        value={this.state.currText}
                      ></input>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    {/* <button
                    className="col-lg-6"
                    className="srtbutton"
                    onClick={this.handleAddNew}
                  >
                    Add New
                  </button> */}
                    <button
                      className="col-lg-6"
                      className="srtbutton"
                      onClick={this.handleSave}
                    >
                      Save
                    </button>
                    {/*status*/}
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6"
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <h1 style={{ fontSize: "24px", fontFamily: "Nunito-Bold" }}>
                    Subtitles
                  </h1>
                  <div
                    className="row font-sizer"
                    style={{
                      fontFamily: "Nunito-Bold",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    <div className="col-2 p-2 font-sizer">Id</div>
                    <div className="col-1 p-2 font-sizer">Start</div>
                    <div className="col-1 p-2 font-sizer">End</div>
                    <div className="col-4 p-2 font-sizer">Text</div>
                    <div className="col-2 p-2 font-sizer"> </div>
                    <div className="col-2 p-2 font-sizer"> </div>
                  </div>
                  <div
                    style={{
                      height: "30vh",
                      overflowY: "auto",
                      overflowX: "hidden",
                      textAlign: "center",
                    }}
                    className="mb-2"
                  >
                    {items}
                  </div>
                </div>
                <div>
                  <h1 style={{ fontSize: "24px", fontFamily: "Nunito-Bold" }}>
                    Last Deleted
                  </h1>
                  <div
                    className="row"
                    style={{
                      textAlign: "center",
                      fontFamily: "Nunito-Bold",
                      fontSize: "18px",
                    }}
                  >
                    <div className="col-2 font-sizer">Id</div>
                    <div className="col-2 font-sizer">Start</div>
                    <div className="col-2 font-sizer">End</div>
                    <div className="col-6 font-sizer">Text</div>
                  </div>
                  <div
                    style={{
                      height: "30vh",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      textAlign: "center",
                    }}
                    className="mb-2"
                  >
                    {deleted_items}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  className="srtbutton"
                  style={{
                    marginTop: 0,
                    textAlign: "center",
                    height: "40px",
                    width: "120px",
                  }}
                  onClick={this.handleCompile}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </Loader>
        {this.state.loaded === false ? (
          <div
            style={{
              height: "70vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <h1 className="explainer">
              Loading in progress do not close the tab.
            </h1>
            <br />
            <p className="sous-explainer">
              Download taking too long? reach out to us on{" "}
              <a
                href="https://discord.gg/r8t79yTgSZ"
                style={{ textDecoration: "none", color: "#7e1fdb" }}
                onClick={() => {
                  mixpanel.track("Discord Button Clicked");
                }}
              >
                Discord
              </a>
            </p>
          </div>
        ) : null}
      </div>
    );
  }
}

export default SrtEdit;
