import mixpanel from "mixpanel-browser";
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mixpanel.init("9afe89b09e92c6e53e4343c97530ff08", {
  debug: true,
  ignore_dnt: true,
});

export default mixpanel;
