import Cookies from "universal-cookie";
class Auth {
  cookies = new Cookies();
  logout() {
    this.cookies.remove("authenticated");
    this.cookies.remove("uid");
  }

  isAuthenticated() {
    if (this.cookies.get("authenticated") === "true") {
      return true;
    } else {
      return false;
    }
  }
  getUID()
  {
    return this.cookies.get("uid");
  }
}

export default new Auth();
