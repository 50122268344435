import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import Audio from "../assets/images/audio.png";
import Video from "../assets/images/video.png";
import upload from "../assets/images/upload-btn.svg";
import Tabs, { TabPane } from "rc-tabs";
import Auth from "../services/auth";
import firebase from "../services/firebase";
import { return_notification, analytics } from "../services/firebase";
import { store } from "react-notifications-component";
import Loader from "react-loader";
import Modal from "./modal";
import $ from "jquery";
import Cookies from "universal-cookie";
import eventBus from "./EventBus";
import mixpanel from "../services/mixpanel";

const axios = require("axios").default;

let url =
  "https://us-central1-pathrise-convert-1606954137718.cloudfunctions.net/generateSignedURL";

var callback = function (key) {};

class NewProject extends Component {
  cookies = new Cookies();
  constructor(props) {
    super(props);
    this.AudioInputRef = React.createRef();
    this.VideoInputRef = React.createRef();
    this.BGImageInputRef = React.createRef();
    this.FileNameInputRef = React.createRef();
    this.state = {
      activeTab: 0,
      AudioFile: "",
      VideoFile: "",
      ImageFile: "",
      uploaded: true,
      projname: "",
      perc_upload: 0,
      showModal: false,
      videotrigger: true,
    };
  }

  componentDidMount() {
    console.log(this.state);
    eventBus.on("videoApply", (datas) =>
      this.setState({ showModal: datas.trigger, videotrigger: datas.trigger })
    );
  }

  componentWillUnmount() {
    eventBus.remove("videoApply");
  }

  handleClick1 = () => {
    if (
      this.state["VideoFile"] == "" ||
      this.state["VideoFile"] == null ||
      this.state["VideoFile"]["name"] == ""
    ) {
      if (
        this.state["AudioFile"] == "" ||
        this.state["AudioFile"] == null ||
        this.state["AudioFile"]["name"] == ""
      ) {
        this.showAlert("Video File required");
        return;
      }
    }
    $(".rc-tabs-tab.rc-tabs-tab-active").next(".rc-tabs-tab").trigger("click");
    analytics.logEvent("no-signup_Project_file_uploaded");
  };

  handleClick2 = () => {
    // if (this.state["ImageFile"] == null || this.state["ImageFile"] == "") {
    //       this.showAlert("ImageFile required");
    //       return;
    // }
    $(".rc-tabs-tab.rc-tabs-tab-active").next(".rc-tabs-tab").trigger("click");
    analytics.logEvent("no-signup_Image_file_uploaded_incl_default");
    mixpanel.track("Image_file_uploaded_incl_default");
  };

  toggleModalDisplay = () => {
    analytics.logEvent("no-signup_complete_project_upload_attempt");
    mixpanel.track("complete_project_upload_attempt");
    this.setState({ showModal: !this.state.showModal });
  };
  showAlert = (msg, info = " ", type = "warning") => {
    store.addNotification({ ...return_notification(msg, info, type) });
  };
  handleAudioUploadClick = (e) => {
    analytics.logEvent("Audio_file_upload_attempt_event");
    mixpanel.track("Audio_file_upload_attempt_event");
    this.AudioInputRef.current.click();
  };
  handleVideoUploadClick = (e) => {
    analytics.logEvent("Video_file_upload_attempt_event");
    mixpanel.track("Video_file_upload_attempt_event");
    this.VideoInputRef.current.click();
  };
  handleBGUploadClick = (e) => {
    analytics.logEvent("Image_file_upload_attempt_event");
    mixpanel.track("Image_file_upload_attempt_event");
    this.BGImageInputRef.current.click();
  };
  handleInputTextChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  loadVideo = (file) =>
    new Promise((resolve, reject) => {
      try {
        let video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = function () {
          resolve(this);
        };

        video.onerror = function () {
          reject("Invalid video. Please select a video file.");
        };

        video.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });

  handleChange = async (e) => {
    if (e.target.files[0] != null) {
      const videoaudiofile = e.target.files[0].type.slice(0, 5);
      if (videoaudiofile == "video" || videoaudiofile == "audio") {
        const audiovideo = await this.loadVideo(e.target.files[0]);
        if (
          e.target.name == "VideoFile" &&
          videoaudiofile == "video" &&
          audiovideo.duration >= 900
        ) {
          mixpanel.track("Project_file_uploaded");
          if (this.state.projname === "") {
            this.setState({
              projname: e.target.files[0].name,
            });
          }
          this.setState({
            [e.target.name]: e.target.files[0],
          });
          // this.filenameVal = e.target.files[0].name;
        } else if (
          e.target.name == "AudioFile" &&
          videoaudiofile == "audio" &&
          audiovideo.duration >= 900
        ) {
          mixpanel.track("Project_file_uploaded");
          if (this.state.projname === "") {
            this.setState({
              projname: e.target.files[0].name,
            });
          }
          this.setState({
            [e.target.name]: e.target.files[0],
          });
          // this.filenameVal = e.target.files[0].name;
          mixpanel.track("Project_file_uploaded");
          if (this.state.projname === "") {
            this.setState({
              projname: e.target.files[0].name,
            });
          }
          this.setState({
            [e.target.name]: e.target.files[0],
          });
        } else {
          this.showAlert(
            "If you try to upload videos/audio less than 15 minutes, the upload will fail"
          );
        }
      }
      if (videoaudiofile == "image") {
        this.setState({
          [e.target.name]: e.target.files[0],
        });
      }
    }
  };

  handleUpload = () => {
    console.log(this.state);
    if (
      this.state["VideoFile"] == "" ||
      this.state["VideoFile"] == null ||
      this.state["VideoFile"]["name"] == ""
    ) {
      if (
        this.state["AudioFile"] == "" ||
        this.state["AudioFile"] == null ||
        this.state["AudioFile"]["name"] == ""
      ) {
        this.showAlert("Video File required");
        return;
      }
    }

    this.setState({ uploaded: false });
    var userId = Auth.getUID();
    if (this.state["VideoFile"] !== "") {
      var ProjKey = firebase
        .database()
        .ref()
        .child("users/" + Auth.cookies.get("uid") + "/projects/")
        .push()
        .getKey();
      firebase
        .database()
        .ref()
        .child(
          "users/" + Auth.cookies.get("uid") + "/projects/" + ProjKey + "/"
        )
        .set({
          name: this.state["projname"],
          clips: "none",
          type: "video",
          defaultBackground:
            this.state["ImageFile"]["name"] != null ? false : true,
        })
        .then((res) => {
          this.FileUpload(
            this.state["VideoFile"],
            userId + "/" + ProjKey,
            "video"
          );
          analytics.logEvent("Video_file_upload_event", {
            file: this.state["ImageFile"]["name"],
          });
          mixpanel.track("Video_file_upload_event", {
            id: ProjKey,
          });
        });
      if (this.state["ImageFile"]["name"] != null) {
        this.FileUpload(
          this.state["ImageFile"],
          userId + "/" + ProjKey,
          "image"
        );
      }
    } else if (this.state["AudioFile"] !== "") {
      var ProjKey = firebase
        .database()
        .ref()
        .child("users/" + Auth.cookies.get("uid") + "/projects/")
        .push()
        .getKey();
      firebase
        .database()
        .ref()
        .child(
          "users/" + Auth.cookies.get("uid") + "/projects/" + ProjKey + "/"
        )
        .set({
          name: this.state["projname"],
          clips: "none",
          type: "audio",
          defaultBackground:
            this.state["ImageFile"]["name"] != null ? false : true,
        })
        .then((res) => {
          analytics.logEvent("Audio_file_upload_event");
          mixpanel.track("Audio_file_upload_event", {
            id: ProjKey,
          });
          // console.log(auth.cookies.get("uid"));
          this.FileUploads(
            this.state["AudioFile"],
            userId + "/" + ProjKey,
            "audio"
          );
        });
      if (this.state["ImageFile"]["name"] != null) {
        // console.log("in image upload");
        this.FileUploads(
          this.state["ImageFile"],
          userId + "/" + ProjKey,
          "image"
        );
      }
    }
  };

  FileUpload = (file, folderName, type = "video") => {
    // console.log(file.type);
    axios
      .post(url, { filename: folderName + "/" + file.name })
      .then((response) => {
        console.log("response one", response);
        axios
          .put(response.data.url, file, {
            onUploadProgress: (progressEvent) => {
              if (type == "video") {
                console.log("perc upload");
                console.log(progressEvent.loaded / file.size);
                this.setState({
                  perc_upload: progressEvent.loaded / file.size,
                });
              }
            },
            headers: {
              "Content-Type": file.type,
            },
          })
          .then((jsonResponse) => {
            console.log("success : ", jsonResponse);
            if (type == "video") {
              //useHistory().pushState(null, 'uploadSuccess');
              this.showAlert("file uploaded");
              mixpanel.track("complete_project_upload_success");
              this.props.history.push("/uploadSuccess");
            }
          })
          .catch((error) => {
            console.log("errorSubmitVideo : ", error);
            this.showAlert(type + "file upload error");
          });
      })
      .catch((error) => {
        console.log("errorSubmitVideo : ", error);
        this.showAlert("file upload error");
      });
  };
  FileUploads = (file, folderName, type = "audio") => {
    console.log(file.type);
    console.log(file.size);
    axios
      .post(url, { filename: folderName + "/" + file.name })
      .then((response) => {
        console.log("response one", response);
        axios
          .put(response.data.url, file, {
            onUploadProgress: (progressEvent) => {
              if (type == "audio") {
                console.log("perc upload");
                console.log(progressEvent.loaded / file.size);
                this.setState({
                  perc_upload: progressEvent.loaded / file.size,
                });
              }
            },
            headers: {
              "Content-Type": file.type,
            },
          })
          .then((jsonResponse) => {
            console.log("success : ", jsonResponse);
            if (type == "audio" || type == "audio") {
              this.showAlert("file uploaded");
              mixpanel.track("complete_project_upload_success");
              this.props.history.push("/uploadSuccess");
            }
          })
          .catch((error) => {
            console.log("errorSubmitVideo : ", error);
            this.showAlert(type + "file upload error");
          });
      })
      .catch((error) => {
        console.log("errorSubmitVideo : ", error);
        this.showAlert("file upload error");
      });
  };
  // FileUploadImages = (file, folderName, type = "image") => {
  //   console.log(file.type, "images - file.type");
  //   console.log(file.type, "images - file.type");
  //   axios
  //     .post(url, { filename: folderName + "/" + file.name })
  //     .then((response) => {
  //       console.log("response one", response);
  //       axios
  //         .put(response.data.url, file, {
  //           onUploadProgress: (progressEvent) => {
  //             if (type == "image") {
  //               console.log("perc upload");
  //               console.log(progressEvent.loaded / file.size);
  //               this.setState({
  //                 perc_upload: progressEvent.loaded / file.size,
  //               });
  //             }
  //           },
  //           headers: {
  //             "Content-Type": file.type,
  //           },
  //         })
  //         .then((jsonResponse) => {
  //           console.log("success : ", jsonResponse);
  //           if (type == "image" || type == "image") {
  //             this.showAlert("file uploaded");
  //             this.props.history.push("/uploadSuccess");
  //           }
  //         })
  //         .catch((error) => {
  //           console.log("errorSubmitVideo : ", error);
  //           this.showAlert(type + "file upload error");
  //         });
  //     })
  //     .catch((error) => {
  //       console.log("errorSubmitVideo : ", error);
  //       this.showAlert("file upload error");
  //     });
  // };
  render() {
    let card;
    if (this.state.activeTab == 0) {
      card = (
        <Loader loaded={this.state.uploaded}>
          <div
            className="videoupload-section"
            onClick={this.handleVideoUploadClick}
          >
            <input
              type="file"
              onChange={this.handleChange}
              name={"VideoFile"}
              style={{ display: "none" }}
              accept={".mp4"}
              ref={this.VideoInputRef}
            />
            <h3>Video File</h3>
            <div className="lower-text">
              {this.state["VideoFile"] == null || this.state["VideoFile"] == ""
                ? ""
                : "FILE: " + this.state["VideoFile"].name}
            </div>
            <div className="lower-text mb-3">Acceptable formats: mp4</div>
            <div className="upload-btn">
              <img src={upload} />
              <p>Upload File</p>
            </div>
          </div>
        </Loader>
      );
    } else {
      card = (
        <Loader loaded={this.state.uploaded}>
          <div
            className="videoupload-section"
            onClick={this.handleAudioUploadClick}
          >
            <input
              type="file"
              onChange={this.handleChange}
              name={"AudioFile"}
              style={{ display: "none" }}
              accept={".mp3, .wav, .m4a"}
              ref={this.AudioInputRef}
            />
            <h3>Audio File</h3>
            <div className="lower-text">
              {this.state["AudioFile"] == null || this.state["AudioFile"] == ""
                ? ""
                : "FILE: " + this.state["AudioFile"].name}
            </div>
            <div className="lower-text mb-3">
              Acceptable formats: mp3, m4a, wav
            </div>
            <div className="upload-btn">
              <img src={upload} />
              <p>Upload File</p>
            </div>
          </div>
        </Loader>
      );
    }
    if (this.state.show === false) {
      return null;
    }
    var showAlert = (msg, info = " ", type = "warning") => {
      store.addNotification({ ...return_notification(msg, info, type) });
    };
    return (
      <div className="container-fluid audio-video-main">
        <div className="audio-video-tabs">
          <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab="Upload the podcast" key="1">
              <Loader loaded={this.state.uploaded}>
                <div className="video-audio-btn">
                  <div
                    className={
                      this.state.activeTab == 0
                        ? "video-btn active"
                        : "video-btn"
                    }
                    onClick={() => this.setState({ activeTab: 0 })}
                  >
                    <svg
                      width="37"
                      height="23"
                      viewBox="0 0 37 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M28.7778 8.625V1.91667C28.7778 0.8625 27.8528 0 26.7222 0H2.05556C0.925 0 0 0.8625 0 1.91667V21.0833C0 22.1375 0.925 23 2.05556 23H26.7222C27.8528 23 28.7778 22.1375 28.7778 21.0833V14.375L37 22.0417V0.958333L28.7778 8.625ZM22.6111 13.4167H16.4444V19.1667H12.3333V13.4167H6.16667V9.58333H12.3333V3.83333H16.4444V9.58333H22.6111V13.4167Z" />
                    </svg>
                    <p>Video file</p>
                  </div>
                  <div
                    className={
                      this.state.activeTab == 1
                        ? "audio-btn active"
                        : "audio-btn"
                    }
                    onClick={() => this.setState({ activeTab: 1 })}
                  >
                    <svg
                      width="20"
                      height="29"
                      viewBox="0 0 20 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9.23077 0V14.9511C8.50769 14.6772 7.73846 14.5 6.92308 14.5C3.09231 14.5 0 17.7383 0 21.75C0 25.7617 3.09231 29 6.92308 29C10.4769 29 13.3846 26.1806 13.7692 22.5556H13.8462V4.83333H20V0H9.23077Z" />
                    </svg>
                    <p>Audio file</p>
                  </div>
                </div>
                {card}
                <div className="next-btn" onClick={this.handleClick1}>
                  Next
                </div>
              </Loader>
            </TabPane>
            <TabPane tab="Upload the image" key="2">
              <Loader loaded={this.state.uploaded}>
                <p className="images-dscription">
                  This will serve as the background of your clips!{" "}
                  <span>(optional)</span>
                </p>
                <div
                  className="imagesupload-section"
                  onClick={this.handleBGUploadClick}
                >
                  <input
                    type="file"
                    onChange={this.handleChange}
                    name={"ImageFile"}
                    style={{ display: "none" }}
                    accept={".jpg,.png"}
                    ref={this.BGImageInputRef}
                  />
                  <h3>Image File</h3>
                  <div className="mt-2">
                    {" "}
                    {this.state["ImageFile"] == null ||
                    this.state["ImageFile"] == ""
                      ? ""
                      : "FILE: " + this.state["ImageFile"].name}
                  </div>
                  <p>Acceptable formats: jpeg, png</p>
                  <div className="upload-btn-images">
                    <img src={upload} />
                    <p>Upload File</p>
                  </div>
                </div>
                <div className="next-btn" onClick={this.handleClick2}>
                  Next
                </div>
              </Loader>
            </TabPane>
            <TabPane tab="Name your project" key="3">
              <Loader loaded={this.state.uploaded}>
                <div className="project-name">
                  <h1>Project Name</h1>
                  <p className="home-sub">
                    what would you like to call your project
                  </p>
                  <input
                    className="modal-input"
                    type="text"
                    name="projname"
                    onChange={this.handleInputTextChange}
                    value={this.state.projname}
                    placeholder="Enter project name here ..."
                  />
                  <br />
                </div>
                {/* <div className="next-btn project-name-done" onClick={() => {
                          console.log(this.state.projName);
                          if (this.state.projname !== "") {
                            this.handleUpload();
                          } else {
                            showAlert("Please Fill the project name");
                          }
                        }}>
                    Done
                </div>  */}
                {this.cookies.get("authenticated") === "true" ||
                this.state.projname == "" ? (
                  <div
                    className="next-btn project-name-done authvideo-upload"
                    onClick={() => {
                      mixpanel.track("complete_project_upload_attempt");
                      if (this.state.projname !== "") {
                        this.handleUpload();
                      } else {
                        showAlert("Please Fill the project name");
                      }
                    }}
                  >
                    Done
                  </div>
                ) : (
                  <div
                    className="next-btn project-name-done"
                    onClick={this.toggleModalDisplay}
                  >
                    Done
                  </div>
                )}
                <Modal
                  show={this.state.showModal}
                  toggleModalDisplay={this.toggleModalDisplay}
                  close={() =>
                    this.setState({ ...this.state, showModal: false })
                  }
                />
              </Loader>
            </TabPane>
          </Tabs>
          {this.state.uploaded === false ? (
            <div
              style={{
                height: "60vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <div className="explainer">
                <div
                  className="progress"
                  style={{ width: "50%", margin: "auto", textAlign: "center" }}
                >
                  <div
                    className="progress-bar progress-bar-striped"
                    role="progressbar"
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: this.state["perc_upload"] * 100 + "%" }}
                  ></div>
                </div>
                <div style={{ padding: "10px" }}></div>
                <h1>
                  Your project is being uploaded, do not close this tab until it
                  is done.
                </h1>
              </div>
              <p className="sous-explainer">
                If you have any issues, feel free to reach out to us @
                <a
                  href="https://discord.gg/r8t79yTgSZ"
                  style={{ textDecoration: "none", color: "#7e1fdb" }}
                >
                  Discord
                </a>
              </p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default NewProject;
