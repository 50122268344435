import React, { Component } from "react";
import { store } from "react-notifications-component";
import { auth, return_notification } from "../services/firebase";
import Auth from "../services/auth";
import { analytics } from "../services/firebase";
import Loader from "react-loader";
import Cohere from "cohere-js";
import mixpanel from "../services/mixpanel";
import { facebookProvider, googleProvider } from "../services/firebase";

class Login extends Component {
  state = { currentUser: null, email: "", password: "", loaded: false };
  async componentDidMount() {
    analytics.logEvent("Login_Page_Opened");
    mixpanel.track("Login_Page_Opened");
    if (Auth.cookies.get("uid") !== undefined) {
      this.props.history.replace("/projectList");
    }
    this.baseState = this.state;
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (user) => {
      await this.setState({ currentUser: user });
    });
    this.setState({ loaded: true });
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  handleSubmit = (e) => {
    this.setState({ loaded: false });
    mixpanel.register({ "First Time": "FALSE" });
    analytics.logEvent("Login", {
      email: this.state.email,
    });
    mixpanel.track("Login", {
      email: this.state.email,
    });
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((userAuth) => {
        Auth.cookies.set("authenticated", true, {
          path: "/",
          maxAge: 2 * 60 * 60,
        });
        Cohere.identify(
          userAuth.user.uid, // Required: can be any unique ID
          {
            displayName: userAuth.user.uid, // Optional
            email: this.state.email, // Optional
          }
        );
        Auth.cookies.set("uid", userAuth.user.uid, {
          path: "/",
          maxAge: 2 * 60 * 60,
        });
        analytics.logEvent("login_action_complete", {
          email: this.state.email,
          uid: userAuth.user.uid,
        });
        mixpanel.track("Login_action_complete", {
          email: this.state.email,
          uid: userAuth.user.uid,
        });
        mixpanel.identify(userAuth.user.uid);
        mixpanel.people.set({
          "Plan Type": "free",
          $email: this.state.email,
          $uid: userAuth.user.uid,
        });
        this.props.history.push("/projectList");
      })
      .catch((err) => {
        store.addNotification({
          ...return_notification(err.code, err.message, "danger"),
        });
        this.setState(this.baseState);
        this.setState({ loaded: true });
      });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  signInWithGoogle = () => {
    this.setState({ loaded: false });
    auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        Auth.cookies.set("username", res.user.displayName, {
          path: "/",
          maxAge: 2 * 60 * 60,
        });
        Auth.cookies.set("authenticated", true, {
          path: "/",
          maxAge: 2 * 60 * 60,
        });
        Cohere.identify(
          res.user.uid, // Required: can be any unique ID
          {
            displayName: res.user.uid, // Optional
            email: res.user.email, // Optional
          }
        );
        Auth.cookies.set("uid", res.user.uid, {
          path: "/",
          maxAge: 2 * 60 * 60,
        });
        analytics.logEvent("login_action_complete", {
          email: res.user.email,
          uid: res.user.uid,
        });
        mixpanel.track("Login_action_complete", {
          email: res.user.email,
          uid: res.user.uid,
        });
        mixpanel.identify(res.user.uid);
        mixpanel.people.set({
          "Plan Type": "free",
          $email: res.user.email,
          $uid: res.user.uid,
        });
        this.props.history.push("/projectList");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  signInWithfacebook = () => {
    auth
      .signInWithPopup(facebookProvider)
      .then((res) => {
        Auth.cookies.set("username", res.user.displayName, {
          path: "/",
          maxAge: 2 * 60 * 60,
        });
        Auth.cookies.set("authenticated", true, {
          path: "/",
          maxAge: 2 * 60 * 60,
        });
        Cohere.identify(
          res.user.uid, // Required: can be any unique ID
          {
            displayName: res.user.uid, // Optional
            email: res.user.email, // Optional
          }
        );
        Auth.cookies.set("uid", res.user.uid, {
          path: "/",
          maxAge: 2 * 60 * 60,
        });
        analytics.logEvent("login_action_complete", {
          email: res.user.email,
          uid: res.user.uid,
        });
        mixpanel.track("Login_action_complete", {
          email: res.user.email,
          uid: res.user.uid,
        });
        mixpanel.identify(res.user.uid);
        mixpanel.people.set({
          "Plan Type": "free",
          $email: res.user.email,
          $uid: res.user.uid,
        });
        this.props.history.push("/projectList");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  render() {
    return (
      <Loader loaded={this.state.loaded}>
        <div className="row">
          <div style={{ padding: "40px" }} />
          <div className="col-lg-7">
            <div className="head-container">
              <h1 className="heading">
                Get <span style={{ color: "#7E1FDB" }}>automatic</span> <br />
                <span
                  className="login-box"
                  style={{
                    borderRadius: "0",
                    padding: "6px",
                    color: "white",
                  }}
                >
                  highlights
                </span>{" "}
                <br /> for your podcast <br />
                <span style={{ color: "#E85F64" }}>in 30 mins or less!</span>
              </h1>
              <h2 className="home-sub">
                Use Tassle, and save 30-45 minutes from your editing time,
                today!
              </h2>
            </div>
          </div>
          <div className="col-lg-5 flex-center-row">
            <div className="login-box">
              <br />
              <br />
              <form onSubmit={this.handleSubmit}>
                <input
                  value={this.state.email}
                  name="email"
                  className="form-control p-2 custom-input"
                  placeholder="Enter Email"
                  required
                  onChange={this.handleChange}
                ></input>
                <br />
                <input
                  value={this.state.password}
                  name="password"
                  type="password"
                  className="form-control p-2 custom-input"
                  placeholder="Enter Password"
                  required
                  onChange={this.handleChange}
                ></input>
                <br />
                <button className="login-button p-2">Login</button>
              </form>
              <p className="or" style={{ textAlign: "center" }}>
                Or
              </p>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  mixpanel.track("Create Account Clicked");
                  this.props.history.replace("/");
                }}
                className="p-2 create-account-button"
              >
                Create New Account
              </div>
              <div class="open-ids mt-3"> 
                <div class="auth-provider google-login" onClick={this.signInWithGoogle}>
                    <svg aria-hidden="true" class="svg-icon" width="18" height="18" viewBox="0 0 18 18">
                      <g>
                          <path d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18z" fill="#4285F4"></path>
                          <path d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17z" fill="#34A853"></path>
                          <path d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07z" fill="#FBBC05"></path>
                          <path d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3z" fill="#EA4335"></path>
                      </g>
                    </svg>
                    Log in with Google
                </div>
                <div class="auth-provider facebook-login" onClick={this.signInWithfacebook}>
                    <svg aria-hidden="true" class="svg-icon" width="18" height="18" viewBox="0 0 18 18">
                      <path d="M1.88 1C1.4 1 1 1.4 1 1.88v14.24c0 .48.4.88.88.88h7.67v-6.2H7.46V8.4h2.09V6.61c0-2.07 1.26-3.2 3.1-3.2.88 0 1.64.07 1.87.1v2.16h-1.29c-1 0-1.19.48-1.19 1.18V8.4h2.39l-.31 2.42h-2.08V17h4.08c.48 0 .88-.4.88-.88V1.88c0-.48-.4-.88-.88-.88H1.88z" fill="#fff"></path>
                    </svg>
                    Log in with Facebook
                </div>
              </div>              
            </div>
          </div>
          <div style={{ paddingBottom: "80px" }} />
        </div>
      </Loader>
    );
  }
}

export default Login;
