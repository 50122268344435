import React from "react";
import { store } from "react-notifications-component";
import { return_notification } from "../services/firebase";
import Signup from "./signup";

const Modal = (props) => {
  if (props.show === false) {
    return null;
  }
  var showAlert = (msg, info = " ", type = "warning") => {
    store.addNotification({ ...return_notification(msg, info, type) });
  };
  return (
    <div className="modal-backdrop">
      <div className="signup-trial modalStyle">
        <Signup uploadshow={true}/>
        {/* <h1 className="heading">Project Name</h1>
        <p className="home-sub">what would you like to call your project</p>

        <input
          className="modal-input"
          type="text"
          name="projname"
          onChange={props.handleProjNameChange}
          value={props.projName}
          placeholder="Enter File Name"
        />
        <br />
        <div className="button-container">
          <button
            className="modal-confirm-button"
            onClick={() => {
              console.log(props.projName);
              if (props.projName !== "") {
                props.handleUpload();
              } else {
                showAlert("Please Fill the project name");
              }
            }}
          >
            Confirm
          </button>
          <button
            className="modal-cancel-button"
            onClick={()=>props.close()}
          >
            Cancel
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Modal;
