import React, { Component } from "react";
import Auth from "../services/auth";
import firebase from "../services/firebase";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import { analytics } from "../services/firebase";
import "../assets/css/projectlist.css";
import mixpanel from "../services/mixpanel";

class ProjectsList extends Component {
  state = {
    userDataObject: {},
    loaded: false,
    new_proj_name: "",
  };
  componentDidMount() {
    this.getFireBaseData();
    analytics.logEvent("Project_list_page");
    mixpanel.track("Project_list_page");
    //console.log(this.state);
  }
  getFireBaseData = () => {
    firebase
      .database()
      .ref()
      .child("users/" + Auth.cookies.get("uid"))
      .on("value", async (snapshot) => {
        if (snapshot.toJSON() === null) {
          await this.setState({ loaded: true });
          return;
        } else {
          await this.setState({
            userDataObject: snapshot.toJSON(),
            loaded: true,
            new_proj_name: "",
          });
        }
      });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    firebase
      .database()
      .ref()
      .child("users/" + Auth.cookies.get("uid") + "/projects/")
      .push()
      .set({ name: this.state.new_proj_name, clips: "none" });
    this.getFireBaseData();
  };
  render() {
    const items = [];
    if (this.state.loaded) {
      if (Object.keys(this.state.userDataObject).indexOf("projects") >= 0) {
        for (const [index, value] of Object.entries(
          this.state.userDataObject.projects
        )) {
          let projnameString = "";
          projnameString = value.name;
          items.push(
            <Link
              to={"/clipList/" + index}
              className="col-lg-3 col-md-6"
              style={{ textDecoration: "none", wordWrap: "break-word" }}
              onClick={() =>
                mixpanel.track("project_opened", {
                  project_id: index,
                })
              }
            >
              <div
                className="project-item"
                key={index}
                style={{
                  borderRadius: "10px",
                  wordWrap: "break-word",
                }}
              >
                <div className="projstringname">{projnameString}</div>
              </div>
            </Link>
          );
        }
      } else {
        items.push(
          <p
            className="m-3 p-3"
            style={{
              fontSize: "24px",
              fontFamily: "Nunito-bold",
            }}
          >
            No Projects Currently
          </p>
        );
      }
    }
    return (
      <Loader loaded={this.state.loaded} options={{ color: "#87FF65" }}>
        <br />
        <div className="container-fluid">
          <h3 className="username p-3">
            Welcome, {this.state.userDataObject.name}{Auth.cookies.get("username")}! 
          </h3>
          <div>
            <div className="row">{items}</div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default ProjectsList;
