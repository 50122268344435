import React, { Component } from "react";
import Modal from "./modal";

class VideoTrial extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }
  toggleModalDisplay = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  render() {
    return (
      <div>
        <Modal
          show={this.state.showModal}
          toggleModalDisplay={this.toggleModalDisplay}
          close={()=>this.setState({...this.state, showModal: false})}
        />
        <button onClick={this.toggleModalDisplay}>Toggle Modal</button>
      </div>
    );
  }
}

export default VideoTrial;
