import React from "react";
import Auth from "../services/auth";
import { auth, analytics } from "../services/firebase";
import { Link, withRouter } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import mixpanel from "../services/mixpanel";
const Navbar = (props) => {
  let urlblog = "https://blog.tassle.xyz";
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top nav-custom">
      <div className="container">
        <a
          className="navbar-brand"
          style={{
            padding: "10px 0",
          }}
          href={Auth.isAuthenticated() ? "/projectList" : "/"}
        >
          <img src={Logo} width="160"></img>
        </a>
        <button
          className="navbar-toggler custom-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
          <div className="d-flex navbar-menu">
            {Auth.cookies.get("uid") === "" ||
            Auth.cookies.get("uid") === undefined ? (
              <div className="home">
                <Link
                  to="/howitworks"
                  className="link"
                  style={{
                    fontSize: "18px",
                    fontFamily: "Nunito-regular",
                    fontWeight: "600",
                    marginRight: "100px",
                  }}
                  onClick={() => mixpanel.track("navbar_FAQ_clicked")}
                >
                  FAQ
                </Link>
                <a
                  href={"https://discord.gg/r8t79yTgSZ"}
                  className="link"
                  style={{
                    fontSize: "18px",
                    fontFamily: "Nunito-regular",
                    fontWeight: "600",
                    marginRight: "100px",
                  }}
                  onClick={() => mixpanel.track("navbar_Community_clicked")}
                >
                  Community
                </a>
                <Link
                  to="/login"
                  className="link"
                  style={{
                    fontSize: "18px",
                    fontFamily: "Nunito-regular",
                    fontWeight: "600",
                  }}
                  onClick={() => {
                    analytics.logEvent(
                      "Log_In_button_clicked_redirected_to_/login"
                    );
                    mixpanel.track(
                      "Log_In_button_clicked_redirected_to_/login"
                    );
                  }}
                >
                  Log In
                </Link>
              </div>
            ) : (
              <div className="home">
                <Link
                  className="link m-3 p-1"
                  style={{ border: "2px solid #7E1FDB", borderRadius: "10px" }}
                  to="/newProject"
                  onClick={() => {
                    analytics.logEvent("Upload_button_clicked");
                    mixpanel.track("Upload_button_clicked");
                  }}
                >
                  Upload
                </Link>
                <Link
                  onClick={() => {
                    auth.signOut();
                    Auth.logout();
                    Auth.cookies.remove("username");
                    props.history.replace("/");
                    analytics.logEvent("Log_out_button_clicked");
                    mixpanel.track("Log_out_button_clicked");
                  }}
                  className="logout m-3"
                >
                  Log out
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(Navbar);
