import React, { Component } from "react";
import Auth from "../services/auth";
import firebase from "../services/firebase";
import { return_notification, analytics } from "../services/firebase";
import { store } from "react-notifications-component";
import Loader from "react-loader";
import Video from "../assets/images/video.png";
import Upload from "../assets/images/upload.png";
import Modal from "./modal";

const axios = require("axios").default;

let url =
  "https://us-central1-pathrise-convert-1606954137718.cloudfunctions.net/generateSignedURL";
class VideoUpload extends Component {
  constructor(props) {
    super(props);
    this.VideoInputRef = React.createRef();
    this.BGImageInputRef = React.createRef();
    this.FileNameInputRef = React.createRef();
    this.state = {
      VideoFile: "",
      ImageFile: "",
      uploaded: true,
      projname: "",
      showModal: false,
      perc_upload: 0,
    };
  }
  componentDidMount() {
    console.log(this.state);
  }
  toggleModalDisplay = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  showAlert = (msg, info = " ", type = "warning") => {
    store.addNotification({ ...return_notification(msg, info, type) });
  };
  handleVideoUploadClick = (e) => {
    this.VideoInputRef.current.click();
  };
  handleBGUploadClick = (e) => {
    this.BGImageInputRef.current.click();
  };
  handleInputTextChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChange = (e) => {
    console.log(e.target.files[0]);
    console.log(e.target.name);
    if (e.target.name == "VideoFile") {
      if (this.state.projname === "") {
        this.setState({
          projname: e.target.files[0].name,
        });
      }
      // this.filenameVal = e.target.files[0].name;
    }
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  };
  handleUpload = () => {
    console.log(this.state);
    if (
      this.state["VideoFile"] == "" ||
      this.state["VideoFile"] == null ||
      this.state["VideoFile"]["name"] == ""
    ) {
      this.showAlert("Video File required");
      return;
    }
    var userId = Auth.getUID();
    this.setState({ uploaded: false });
    var ProjKey = firebase
      .database()
      .ref()
      .child("users/" + Auth.cookies.get("uid") + "/projects/")
      .push()
      .getKey();
    firebase
      .database()
      .ref()
      .child("users/" + Auth.cookies.get("uid") + "/projects/" + ProjKey + "/")
      .set({ name: this.state["projname"], clips: "none", type: "video" })
      .then((res) => {
        this.FileUpload(
          this.state["VideoFile"],
          userId + "/" + ProjKey,
          "video"
        );
        analytics.logEvent("Video_file_upload_event", {
          file: this.state["ImageFile"]["name"],
        });
        console.log(this.state["ImageFile"]["name"]);
        if (this.state["ImageFile"]["name"] != null) {
          this.FileUpload(
            this.state["ImageFile"],
            userId + "/" + ProjKey,
            "image"
          );
        }
      });
  };
  FileUpload = (file, folderName, type = "video") => {
    console.log(file.type);
    axios
      .post(url, { filename: folderName + "/" + file.name })
      .then((response) => {
        console.log("response one", response);
        axios
          .put(response.data.url, file, {
            onUploadProgress: (progressEvent) => {
              if (type == "video") {
                console.log("perc upload");
                console.log(progressEvent.loaded / file.size);
                this.setState({
                  perc_upload: progressEvent.loaded / file.size,
                });
              }
            },
            headers: {
              "Content-Type": file.type,
            },
          })
          .then((jsonResponse) => {
            console.log("success : ", jsonResponse);
            if (type == "video") {
              //useHistory().pushState(null, 'uploadSuccess');
              this.showAlert("file uploaded");
              this.props.history.push("/uploadSuccess");
            }
          })
          .catch((error) => {
            console.log("errorSubmitVideo : ", error);
            this.showAlert(type + "file upload error");
          });
      })
      .catch((error) => {
        console.log("errorSubmitVideo : ", error);
        this.showAlert("file upload error");
      });
  };
  render() {
    return (
      <div className="container-fluid">
        <h1 className="username p-3">Upload a new Project</h1>
        <Loader loaded={this.state.uploaded}>
          <h3 className="sub-heading" style={{ textAlign: "center" }}>
            Video File
          </h3>
          <br />
          <div className="row m-3">
            <div className="col-lg-3"></div>

            <div
              className="col-lg-3 mb-3"
              onClick={this.handleVideoUploadClick}
            >
              <div
                className="selection-box"
                style={{
                  display: "flex",
                  alignContent: "center",
                  background: "#E85F64",
                }}
              >
                <input
                  type="file"
                  onChange={this.handleChange}
                  name={"VideoFile"}
                  style={{ display: "none" }}
                  accept={".mp4"}
                  ref={this.VideoInputRef}
                />
                <img
                  src={Video}
                  style={{
                    width: "20%",
                    textAlign: "center",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                ></img>
                <div className="selection-item">Upload a Video</div>
                <div className="lower-text">
                  {this.state["VideoFile"] == null ||
                  this.state["VideoFile"] == ""
                    ? ""
                    : "FILE: " + this.state["VideoFile"].name}
                </div>
                <div className="lower-text">Acceptable formats: mp4</div>
                <div className="upload-file-button">
                  <div>
                    <img
                      src={Upload}
                      style={{
                        textalign: "center",
                      }}
                      height="20px"
                      width="auto"
                    />
                  </div>
                  <div style={{ marginRight: "16px" }}></div>
                  <div>Upload File</div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-3" onClick={this.handleBGUploadClick}>
              <div
                className="selection-box"
                style={{
                  display: "flex",
                  alignContent: "center",
                  background: "#E85F64",
                }}
              >
                <input
                  type="file"
                  onChange={this.handleChange}
                  name={"ImageFile"}
                  style={{ display: "none" }}
                  accept={".jpg,.png"}
                  ref={this.BGImageInputRef}
                />
                <img
                  src={Upload}
                  style={{
                    width: "20%",
                    textAlign: "center",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                ></img>
                <div className="selection-item">Upload a background image</div>
                <div className="lower-text">Acceptable formats: jpg,png</div>
                <div className="upload-file-button">
                  <div>
                    <img
                      src={Upload}
                      style={{
                        textalign: "center",
                      }}
                      height="20px"
                      width="auto"
                    />
                  </div>
                  <div style={{ marginRight: "16px" }}></div>
                  <div>Upload File</div>
                </div>
                <div className="lower-text">
                  {" "}
                  {this.state["ImageFile"] == null ||
                  this.state["ImageFile"] == ""
                    ? ""
                    : "FILE: " + this.state["ImageFile"].name}
                </div>
              </div>
              <p className="instruction-text m-3">
                Upload only if you want to generate an audiogram instead of a
                video clip
                <span style={{ color: "#00CA81" }}> (optional*)</span>
              </p>
            </div>
            <div className="col-lg-3 mb-3"></div>
          </div>
          <br />
          <Modal
            show={this.state.showModal}
            handleProjNameChange={this.handleInputTextChange}
            toggleModalDisplay={this.toggleModalDisplay}
            projName={this.state.projname}
            handleUpload={this.handleUpload}
            close={() => this.setState({ ...this.state, showModal: false })}
          />
          <div>
            <button
              className="upload m-3 p-2 mx-auto"
              onClick={this.toggleModalDisplay}
            >
              Upload
            </button>
          </div>
        </Loader>
        {this.state.uploaded === false ? (
          <div
            style={{
              height: "60vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div className="explainer">
              <div
                className="progress"
                style={{ width: "50%", margin: "auto", textAlign: "center" }}
              >
                <div
                  className="progress-bar progress-bar-striped"
                  role="progressbar"
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: this.state["perc_upload"] * 100 + "%" }}
                ></div>
              </div>
              <div style={{ padding: "10px" }}></div>
              <h1>
                Your video is being uploaded, do not close this tab until it is
                done.
              </h1>
            </div>
            <p className="sous-explainer">
              If you have any issues, feel free to email us @
              <a
                href="https://discord.gg/r8t79yTgSZ"
                style={{ textDecoration: "none", color: "#7e1fdb" }}
              >
                Discord
              </a>
            </p>
          </div>
        ) : null}
      </div>
    );
  }
}

export default VideoUpload;
