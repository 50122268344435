import React, { Component } from "react";
import { store } from "react-notifications-component";
import firebase, {
  auth,
  return_notification,
  analytics,
} from "../services/firebase";
import mixpanel from "../services/mixpanel";
import Auth from "../services/auth";
import Spinner from "react-loader-spinner";
import { withRouter } from "react-router";
import eventBus from "./EventBus";
import Cohere from "cohere-js";
import $ from "jquery";
import { facebookProvider, googleProvider } from "../services/firebase";

class Signup extends Component {
  state = { email: "", password: "", name: "", loaded: false };

  constructor(props) {
    super(props);
    this.state = {
      uploadvideocommand: props.uploadshow,
    };
    if (this.props.history.datavaule != "") {
      this.state.email = this.props.history.datavaule;
    }
  }

  componentDidMount() {
    this.baseState = this.state;
    this.setState({ loaded: true });
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    this.setState({ loaded: false });
    e.preventDefault();
    auth
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then((userAuth) => {
        firebase
          .database()
          .ref("users/" + userAuth.user.uid)
          .set({ email: this.state.email, name: this.state.name })
          .catch((err) => console.log(err));
        Auth.cookies.set("authenticated", true, {
          path: "/",
          maxAge: 2 * 60 * 60,
        });
        Auth.cookies.set("uid", userAuth.user.uid, {
          path: "/",
          maxAge: 2 * 60 * 60,
        });
        window.gtag("event", "conversion", {
          send_to: "AW-319685830/HQGDCKmpjo4DEMaJuJgB",
        });
        analytics.logEvent("signup_action_complete", {
          email: this.state.email,
          name: this.state.name,
          uid: userAuth.user.uid,
        });
        mixpanel.track("Sign_up_complete", {
          email: this.state.email,
          name: this.state.name,
          uid: userAuth.user.uid,
        });
        mixpanel.identify(userAuth.user.uid);
        mixpanel.people.set({
          "Plan Type": "free",
          $email: this.state.email,
          $uid: userAuth.user.uid,
        });
        if (this.state.uploadvideocommand == true) {
          if (
            this.props.history.datavaule != "" &&
            this.props.history.datavaule != undefined
          ) {
            this.props.history.push("/projectList");
            this.props.history.datavaule.replace("", null);
          } else {
            this.setState({ loaded: true });
            eventBus.dispatch("videoApply", { trigger: false });
            $(".authvideo-upload").trigger("click");
          }
        } else {
          this.props.history.push("/projectList");
        }
      })
      .catch((err) => {
        store.addNotification({
          ...return_notification(err.code, err.message, "danger"),
        });
        this.setState(this.baseState);
        this.setState({ loaded: true });
      });
  };

    signInWithGoogle = () => {
    this.setState({ loaded: false });
    auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        Auth.cookies.set("username", res.user.displayName, {
          path: "/",
          maxAge: 2 * 60 * 60,
        });
        Auth.cookies.set("authenticated", true, {
          path: "/",
          maxAge: 2 * 60 * 60,
        });
        Cohere.identify(
          res.user.uid, // Required: can be any unique ID
          {
            displayName: res.user.uid, // Optional
            email: res.user.email, // Optional
          }
        );
        Auth.cookies.set("uid", res.user.uid, {
          path: "/",
          maxAge: 2 * 60 * 60,
        });
        analytics.logEvent("login_action_complete", {
          email: res.user.email,
          uid: res.user.uid,
        });
        mixpanel.track("Login_action_complete", {
          email: res.user.email,
          uid: res.user.uid,
        });
        mixpanel.identify(res.user.uid);
        mixpanel.people.set({
          "Plan Type": "free",
          $email: res.user.email,
          $uid: res.user.uid,
        });
        if (this.state.uploadvideocommand == true) {
          if (
            this.props.history.datavaule != "" &&
            this.props.history.datavaule != undefined
          ) {
            this.props.history.push("/projectList");
            this.props.history.datavaule.replace("", null);
          } else {
            this.setState({ loaded: true });
            eventBus.dispatch("videoApply", { trigger: false });
            $(".authvideo-upload").trigger("click");
          }
        } else {
          this.props.history.push("/projectList");
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  signInWithfacebook = () => {
    auth
      .signInWithPopup(facebookProvider)
      .then((res) => {
        Auth.cookies.set("username", res.user.displayName, {
          path: "/",
          maxAge: 2 * 60 * 60,
        });
        Auth.cookies.set("authenticated", true, {
          path: "/",
          maxAge: 2 * 60 * 60,
        });
        Cohere.identify(
          res.user.uid, // Required: can be any unique ID
          {
            displayName: res.user.uid, // Optional
            email: res.user.email, // Optional
          }
        );
        Auth.cookies.set("uid", res.user.uid, {
          path: "/",
          maxAge: 2 * 60 * 60,
        });
        analytics.logEvent("login_action_complete", {
          email: res.user.email,
          uid: res.user.uid,
        });
        mixpanel.track("Login_action_complete", {
          email: res.user.email,
          uid: res.user.uid,
        });
        mixpanel.identify(res.user.uid);
        mixpanel.people.set({
          "Plan Type": "free",
          $email: res.user.email,
          $uid: res.user.uid,
        });
        if (this.state.uploadvideocommand == true) {
          if (
            this.props.history.datavaule != "" &&
            this.props.history.datavaule != undefined
          ) {
            this.props.history.push("/projectList");
            this.props.history.datavaule.replace("", null);
          } else {
            this.setState({ loaded: true });
            eventBus.dispatch("videoApply", { trigger: false });
            $(".authvideo-upload").trigger("click");
          }
        } else {
          this.props.history.push("/projectList");
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  render() {
    return this.state.loaded === false ? (
      <div
        className="col-lg-4 mx-auto"
        style={{ margin: "auto", textAlign: "center" }}
      >
        <Spinner type="Circles" color="#000" />
      </div>
    ) : (
      <div className="login-box">
        <div style={{ color: "#fff", textAlign: "center", padding: "10px" }}>
          <h3 style={{ fontFamily: "Nunito-regular", fontWeight: "700" }}>
            Sign Up!
          </h3>
          <p style={{ fontFamily: "Nunito-regular", fontWeight: "500" }}>
            {/* It’s free, quick and easy! */}
            So we can save your clips and tell you when they’re done
          </p>
        </div>
        <form onSubmit={this.handleSubmit}>
          <input
            name="name"
            placeholder="Enter Name"
            required
            className="form-control p-2 custom-input"
            value={this.state.name}
            onChange={this.handleChange}
          ></input>
          <br />
          <input
            name="email"
            placeholder="Enter Email"
            required
            className="form-control p-2 custom-input"
            onChange={this.handleChange}
            value={this.state.email}
          ></input>
          <br />
          <input
            name="password"
            type="password"
            placeholder="Enter Password"
            required
            className="form-control p-2 custom-input"
            onChange={this.handleChange}
            value={this.state.password}
          ></input>
          <br />
          <button className="signup-submit-button">Create Account</button>
          <div class="open-ids mt-3"> 
            <div class="auth-provider google-login" onClick={this.signInWithGoogle}>
                <svg aria-hidden="true" class="svg-icon" width="18" height="18" viewBox="0 0 18 18">
                  <g>
                      <path d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18z" fill="#4285F4"></path>
                      <path d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17z" fill="#34A853"></path>
                      <path d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07z" fill="#FBBC05"></path>
                      <path d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3z" fill="#EA4335"></path>
                  </g>
                </svg>
                Log in with Google
            </div>
            <div class="auth-provider facebook-login" onClick={this.signInWithfacebook}>
                <svg aria-hidden="true" class="svg-icon" width="18" height="18" viewBox="0 0 18 18">
                  <path d="M1.88 1C1.4 1 1 1.4 1 1.88v14.24c0 .48.4.88.88.88h7.67v-6.2H7.46V8.4h2.09V6.61c0-2.07 1.26-3.2 3.1-3.2.88 0 1.64.07 1.87.1v2.16h-1.29c-1 0-1.19.48-1.19 1.18V8.4h2.39l-.31 2.42h-2.08V17h4.08c.48 0 .88-.4.88-.88V1.88c0-.48-.4-.88-.88-.88H1.88z" fill="#fff"></path>
                </svg>
                Log in with Facebook
            </div>
          </div>            
          {/* <button className="signup-submit-button">Sign Up</button> */}
          <br />
        </form>
      </div>
    );
  }
}

export default withRouter(Signup);
